// MIXINS

// COLORS
@function get-color-primary($role) {
  // default: SuperCompanyAdminstrators/CompanyAdminstrators
  $heto-color-primary: $heto-color-role-primary-default;
  @if $role == "students" {
    $heto-color-primary: $heto-color-role-primary-students;
  } @else if $role == "faculties" {
    $heto-color-primary: $heto-color-role-primary-faculties;
  } @else if $role == "institutions" {
    $heto-color-primary: $heto-color-role-primary-institutions;
  }
  @return $heto-color-primary;
}
@function get-color-secondary($role) {
  // default: SuperCompanyAdminstrators/CompanyAdminstrators
  $heto-color-secondary: $heto-color-role-secondary-default;
  @if $role == "students" {
    $heto-color-secondary: $heto-color-role-secondary-students;
  } @else if $role == "faculties" {
    $heto-color-secondary: $heto-color-role-secondary-faculties;
  } @else if $role == "institutions" {
    $heto-color-secondary: $heto-color-role-secondary-institutions;
  }
  @return $heto-color-secondary;
}
@function get-color-rgba($role) {
  // default: SuperCompanyAdminstrators/CompanyAdminstrators
  $heto-color-rgba: $heto-color-role-rgba-default;
  @if $role == "students" {
    $heto-color-rgba: $heto-color-role-rgba-students;
  } @else if $role == "faculties" {
    $heto-color-rgba: $heto-color-role-rgba-faculties;
  } @else if $role == "institutions" {
    $heto-color-rgba: $heto-color-role-rgba-institutions;
  }
  @return $heto-color-rgba;
}

@mixin set-background($role) {
  background: get-color-primary($role);
}
@mixin set-background-rgba($role) {
  background: get-color-rgba($role);
}

@mixin set-background-sidebarmenu($role) {
  @include set-background($role);
  @if $role == "companies" {
    background: linear-gradient(
      get-color-primary($role),
      get-color-secondary($role)
    );
  } @else {
    background: linear-gradient(
      get-color-secondary($role),
      get-color-primary($role)
    );
  }
}

@mixin set-background-menubar($role) {
  @include set-background($role);
  @if $role == "companies" {
    background: linear-gradient(
      get-color-primary($role) 100vh,
      get-color-secondary($role) 0%
    );
  } @else {
    background: linear-gradient(
      get-color-secondary($role) 0%,
      get-color-primary($role) 100vh
    );
  }
}

// ELEMENTS HETO
@mixin skejule-teal($role: "companies") {
  .skejule-teal {
    color: get-color-primary($role) !important;
  }
}
@mixin H1-6($role: "companies") {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: "Montserrat", sans-serif;
    @if $role == "companies" {
      color: #77c0cf;
    } @else {
      color: get-color-primary($role);
    }
  }
}
@mixin skejule-scroll($role: "companies") {
  .skejule-scroll {
    width: 100%;
    overflow-y: auto;
    height: 70vh; /*70vh*/
    border-top: 1px solid rgba(0, 0, 0, 0.05);
    &::-webkit-scrollbar {
      height: 5px !important;
      width: 5px !important;
      background: #dcdcdc !important;
    }
    &::-webkit-scrollbar-track {
      background: #dcdcdc !important;
    }
    &::-webkit-scrollbar-thumb {
      background: get-color-primary($role) !important;
    }
  }
}
@mixin heto-scroll($role: "companies", $isFixed: false) {
  height: calc(100vh - 200px);
  min-height: 450px;
  overflow: auto;
  &::-webkit-scrollbar {
    background: transparent !important;
    height: 5px !important;
    width: 5px !important;
  }
  &::-webkit-scrollbar-track {
    background: #dcdcdc !important;
  }
  &::-webkit-scrollbar-track-piece {
    background: #dcdcdc !important;
  }

  &::-webkit-scrollbar-thumb {
    background: get-color-primary($role) !important;
  }
}
@mixin ui-heto-link() {
  &.link {
    color: $heto-color-blue;
    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
}
/******************* CUSTOM BUTTONS ********************/
@mixin ui-button-skejule($role: "companies") {
  .ui {
    .button {
      &.skejule {
        background-color: #ffffff !important;
        border: 1px solid get-color-primary($role);
        border-radius: 24px !important;
        color: get-color-primary($role) !important;
        box-shadow: 0px 0px 0px !important;
        padding: 3px 10px !important;
        font-size: 14px;

        .label,
        .text {
          color: get-color-primary($role) !important;
        }

        &:focus,
        &:hover,
        &:active {
          background: get-color-primary($role) !important;
          color: #ffffff !important;

          .label,
          .text {
            color: #ffffff !important;
          }
        }

        &.filled {
          background-color: get-color-primary($role) !important;
          border: 1px solid get-color-primary($role) !important;
          color: #ffffff !important;

          .label,
          .text {
            color: #ffffff !important;
          }

          &:focus,
          &:hover,
          &:active {
            background: #ffffff !important;
            color: get-color-primary($role) !important;

            .label,
            .text {
              color: get-color-primary($role) !important;
            }
          }
        }

        &.large {
          font-size: 1.14285714rem;
          padding: 5px 35px !important;
        }

        &.big {
          font-size: 1.28571429rem;
          padding: 7px 50px !important;
        }
      }
    }
  }
}
/******************* CUSTOM BUTTONS ********************/

/******************* HETO CALENDARS ********************/
@mixin ui-heto-calendars() {
  .calendars {
    .rw-calendar {
      border: 0px;
      box-shadow: 0px 0px 0px transparent;
      font-size: 11px;
      .rw-calendar-header {
        font-size: 14px;
        letter-spacing: 1.14px;
        .rw-calendar-btn-left,
        .rw-calendar-btn-right {
          color: $heto-color-teal;
          padding: 0px 5px !important;
          position: absolute;
        }
        .rw-calendar-btn-left {
          left: 0px;
        }
        .rw-calendar-btn-right {
          right: 0px;
        }
        .rw-calendar-btn-view {
          color: $heto-color-teal;
          margin-left: 10%;
          padding: 0px !important;
          width: 80%;
        }
      } /*.rw-calendar-header*/
      .rw-calendar-transition-group {
        .rw-cell,
        table tr th {
          color: #846e6e;
        }
        .rw-cell {
          border-radius: 0px;
          padding: 1em 0.25em;
          .can-be-selected {
            color: $heto-color-teal;
          }
          &.rw-cell-off-range {
            color: #d4bfbf;
            .can-be-selected {
              opacity: 0.5;
            }
          }
          &.rw-now {
            background-color: #e6e6e6;
            border: #e6e6e6 1px solid;
          }
          &:hover {
            .past-date {
              background-color: rgba(219, 40, 40, 0.4);
              border: $heto-color-red 1px solid;
              color: #846e6e;
            }
          }
          &.rw-state-selected {
            background: $heto-color-teal;
            border: 0px;
            color: $heto-color-white;
            .can-be-selected {
              color: $heto-color-white;
            }
          }
        } /*.rw-cell*/
      } /*.rw-calendar-transition-group*/
      &.past-date-selected {
        .rw-calendar-transition-group {
          .rw-cell {
            &.rw-state-selected {
              background: $heto-color-red;
              color: $heto-color-white;
            }
          } /*.rw-cell*/
        } /*.rw-calendar-transition-group*/
      }
    } /*.rw-calendar*/
    .calendar-left {
      border-right: 0px;
      padding: 0px 25px;
    }
    .calendar-right {
      border-left: 0px;
      padding: 0px 25px;
    }
    @media (min-width: 992px) {
      .calendar-left {
        border-right: 2px solid $heto-color-teal;
      }
    }
  }
}
/******************* HETO CALENDARS ********************/
