body.confirm-service-worker {
  .ui.modals.dimmer {
    background-color: rgba(0, 0, 0, 0.85);
    .service-worker-button {
      font-size: 14px;
      button {
        background-image: none;
        border: 1px solid #74c0d0;
        border-radius: 100px;
        padding: 10px 20px;
        box-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
        height: unset;
        color: #74c0d0;
        margin-bottom: 15px;
        font-size: 14px;
        font-weight: normal;
        line-height: 1.42857143;
        text-align: center;
        white-space: nowrap;
        vertical-align: middle;
        &:hover,
        &:active,
        &:focus {
          background: #fff;
          .label {
            color: #74c0d0;
            text-shadow: none;
            outline: 0;
          }
        }
      }
    }
  }
}
