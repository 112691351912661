#root #heto-app-website {
  #main {
    &.menu.notifications {
      .notifications-popup-container {
        .send-broadcast-message {
          .recipient {
            .ui.dropdown.users-roles {
              .menu {
                & > .item {
                  &.lemming {
                    background: transparent !important;
                    .skejule-divider {
                      margin: 0px;
                      cursor: default !important;
                    } /*.skejule-divider*/
                  } /*.lemming*/
                } /*.item*/
              } /*.menu*/
            } /*.ui.dropdown*/
          }
          .expiration-date {
            .rw-datetime-picker {
              input {
                border: 0px;
                color: #999;
                // min-width: 15em;
                // width: 15em;
                padding: 7px 10px !important;
                &::placeholder {
                  opacity: 0.5;
                  font-size: 14px;
                }
              }

              & > .rw-widget-container {
                background-color: transparent;
                border-radius: 18px;
                border: 1px solid #74c0d0;
                color: #74c0d0;
                box-shadow: 0px 0px 0px !important;
                // width: auto;

                button {
                  border: 0px;
                }

                & > .rw-select {
                  position: absolute;
                  top: 0px;
                  right: 52px;

                  &:hover,
                  :active,
                  :focus {
                    background: transparent !important;
                    box-shadow: 0px 0px 0px !important;
                  }

                  &.rw-select-bordered {
                    border-left: 1px solid #74c0d0;
                  }

                  .rw-btn-select {
                    color: #74c0d0;
                    opacity: 1;
                    width: 53px;
                    // height: 38px;
                    padding-top: 0px !important;
                    padding-bottom: 0px !important;
                    border-radius: 0px 18px 18px 0px;
                    overflow: hidden;
                    border: 0px;

                    &:hover {
                      background: #74c0d0 !important;
                      color: #ffffff !important;
                    }
                  } /*.rw-btn-select*/
                } /*> .rw-select*/
              } /*.rw-widget-container*/
              .rw-popup-container {
                background: #ffffff;
                border-radius: 3px;
                border: 2px solid rgba(0, 0, 0, 0.1);

                .rw-popup-transition {
                  padding: 0px;
                  margin: 0px;

                  button {
                    padding: 5px 10px !important;
                  }

                  .rw-calendar {
                    padding: 5px;
                  }

                  .rw-calendar-header {
                    font-size: 18px;
                    letter-spacing: 1.14px;

                    .rw-calendar-btn-left,
                    .rw-calendar-btn-right {
                      color: #74c0d0 !important;
                      position: absolute;
                      z-index: 10;
                      padding: 0px 5px !important;
                      border: 0px;
                    }

                    .rw-calendar-btn-left {
                      left: 0px;
                    }

                    .rw-calendar-btn-right {
                      right: 0px;
                    }

                    .rw-calendar-btn-view {
                      color: #846e6e !important;
                      width: 100%;
                      padding: 0px 20px !important;
                    }
                  } /*.rw-calendar-header*/

                  .rw-calendar-transition-group {
                    .rw-cell-off-range {
                      color: #d4bfbf !important;
                    }

                    .rw-cell,
                    table tr th {
                      color: #846e6e !important;
                    }

                    .rw-cell {
                      &.rw-now {
                        background: transparent !important;
                        border-radius: 100px !important;
                        border: 1px solid #74c0d0;
                      }
                      &.rw-state-selected {
                        background: #74c0d0 !important;
                        color: #ffffff !important;
                        border-radius: 100px !important;
                        border: 0px !important;
                      }
                    }
                  } /*.rw-calendar-transition-group*/

                  .rw-calendar-footer {
                    button {
                      margin-top: 5px;
                    }
                  } /*.rw-calendar-footer*/
                } /*.rw-popup-transition*/
              } /*.rw-popup-container */
            } /*.rw-datetime-picker*/
          }
        }
      }
    }
  }
}
