#root {
  #heto-app-website {
    #main {
      &.notifications {
        .notifications-popup {
          background: transparent;
          display: none;
          left: 50%;
          overflow: hidden;
          position: fixed;
          top: 50%;
          transform: translate(-45%, -50%);
          width: 400px;
          @media (min-width: 650px) {
            width: 650px;
          }
          @media (min-width: 769px) {
            width: 865px;
          }
          &.visible {
            display: block;
          }
          .notifications-popup-container {
            .ui.grid.notifications-content {
              .two.wide.column {
                margin-left: -25px;
                @media (min-width: 769px) {
                  margin-left: -30px;
                  width: 9.5% !important;
                }
              }
              .btn.link {
                cursor: pointer;
                &:hover {
                  text-decoration: underline;
                }
              }
            }
          }

          .ui {
            &.secondary {
              &.pointing {
                &.menu {
                  border: 0px;
                  padding: 0px 5px;
                  @media (min-width: 769px) {
                    padding: 0px;
                  }
                  .item {
                    background: #96d0dd;
                    border: 0px;
                    &.active {
                      background: #74c0d0;
                      .icono.skejule-teal {
                        color: #fff !important;
                      }
                    }
                  } /*.item*/
                } /*.menu*/
              } /*.pointing*/
            } /*.secondary*/
            &.tab {
              &.pane {
                border-radius: 5px;
                border: 5px solid #74c0d0 !important;

                .notifications-filters {
                  overflow-x: auto;
                  width: calc(100% - 0px);
                  &::-webkit-scrollbar {
                    margin-top: 5px;
                    height: 5px !important;
                    background: #dcdcdc !important;
                  }
                  &::-webkit-scrollbar-track {
                    background: #dcdcdc !important;
                  }
                  &::-webkit-scrollbar-thumb {
                    background: #74c0d0 !important;
                  }

                  .ui.button.skejule {
                    &:first-child {
                      border-left: 1px solid #6ac7da;
                    }
                    i.icon.delete {
                      display: none;
                    }
                    &.filled {
                      &.active:active,
                      &.active:focus {
                        background: #6ac7da !important;
                        color: #ffffff !important;
                      }
                      i.icon.delete {
                        background: #fff;
                        bottom: 14px;
                        box-shadow: 0 0 0 0.1em #6ac7da inset;
                        display: inline-block;
                        left: 5px;
                        opacity: 1;
                      }
                    }
                  }
                }

                .notifications-list {
                  .notification {
                    border-bottom: 2px solid #74c0d0;
                    color: #9b9b9b;
                    padding: 5px;
                    text-align: left;
                    line-height: 16px;

                    a {
                      color: #3765b3 !important;
                    }

                    .ui {
                      &.grid {
                        display: contents;
                      }
                    } /*.ui*/

                    .message {
                      margin-bottom: 10px;
                      position: relative;
                    }

                    .datetime {
                      font-size: 12px;
                      color: #b9b9b9;

                      span {
                        margin-right: 3px;
                      }

                      .actions {
                        bottom: 3px;
                      }
                    }

                    &.new {
                      background: rgba(155, 155, 155, 0.1);
                      .message {
                        &:before {
                          content: "new";
                          padding: 1px 5px;
                          background: #3765b3;
                          color: #ffffff;
                          margin-right: 5px;
                          border-radius: 3px;
                          font-size: 11px;
                          position: relative;
                          top: -1px;
                        } /*:before*/
                      } /*.message*/
                    } /*.new*/

                    &:last-child {
                      border-bottom: 0px !important;
                    }
                  } /*.notification*/
                } /*.notification-list*/

                &.broadcast-messages {
                  &.skejule-scroll {
                    overflow-x: hidden;
                    height: calc(100vh - 95px);
                    @media (max-height: 1080px) {
                      height: calc(100vh - 59px);
                    }
                    @media (max-height: 900px) {
                      height: calc(100vh - 5px);
                    }
                  }
                  .send-broadcast-message {
                    color: #9b9b9b;
                    text-align: left;
                    line-height: 18px;
                    border-radius: 18px;

                    .ui.dropdown {
                      margin-bottom: 10px;
                      &.multiple.search {
                        .label {
                          background: #74c0d0;
                          border-radius: 10px;
                          color: #ffffff;
                          box-shadow: 0px 0px 0px;
                        }
                        & > input.search {
                          padding: 0 !important;
                        }
                        .menu.transition {
                          .item:first-child {
                            margin-top: 0px;
                          }
                        }
                      }
                    }
                    .dropdown,
                    textarea {
                      border-radius: 18px;
                      border-color: #74c0d0;
                    }
                  }
                  .broadcast-schedulers {
                    clear: both;
                    .expiration-date .ui.red.tiny.label.expired {
                      background-color: #d0021b !important;
                      border-color: #d0021b !important;
                      color: #fff !important;
                    }
                  }
                }
              } /*.pane*/
            } /*.tab*/
          } /*.ui*/
        } /*.notifications-popup*/

        .widget {
          .notifications-list {
            .notification {
              border-bottom: 2px solid #74c0d0;
              color: #9b9b9b !important;
              padding: 5px;
              text-align: left;
              line-height: 16px;
              font-size: 12px !important;

              a {
                color: #3765b3 !important;
              }

              .ui {
                &.grid {
                  display: contents;
                }
              } /*.ui*/

              .message {
                margin-bottom: 10px;
                position: relative;
              }

              .datetime {
                font-size: 12px;
                color: #b9b9b9;

                span {
                  margin-right: 3px;
                }
              }

              &.new {
                .message {
                  &:before {
                    content: "new";
                    padding: 1px 5px;
                    background: #3765b3;
                    color: #ffffff;
                    margin-right: 5px;
                    border-radius: 3px;
                    font-size: 11px;
                    position: relative;
                    top: -1px;
                  } /*:before*/
                } /*.message*/
              } /*.new*/

              &:last-child {
                border-bottom: 0px !important;
              }

              img {
                max-width: 28px;
              }
              .ui {
                & > .row {
                  & > .column {
                    padding: 1rem;
                  }
                }
              }
            } /*.notification*/
          } /*.notification-list*/
        }
      } /*.notifications*/
    } /*#main*/
  }
  /*REMINDER WIDGET*/
  // #main {
  //
  // 		.widget {
  // 			.notifications-list {
  // 				.notification {
  // 					border-bottom: 2px solid #74C0D0;
  // 					color: #9B9B9B !important;
  // 					padding: 5px;
  // 					text-align: left;
  // 					line-height: 16px;
  // 					font-size: 12px !important;
  //
  // 					a {
  // 						color: #3765B3 !important;
  // 					}
  //
  // 					.ui {
  // 						&.grid {
  // 							display: contents;
  // 						}
  // 					}/*.ui*/
  //
  // 					.message {
  // 						margin-bottom: 10px;
  // 						position: relative;
  // 					}
  //
  // 					.datetime {
  // 						font-size: 12px;
  // 						color: #B9B9B9;
  //
  // 						span {
  // 							margin-right: 3px;
  // 						}
  // 					}
  //
  // 					&.new {
  // 						.message {
  // 							&:before {
  // 								content: "new";
  // 								padding: 1px 5px;
  // 								background: #3765b3;
  // 								color: #FFFFFF;
  // 								margin-right: 5px;
  // 								border-radius: 3px;
  // 								font-size: 11px;
  // 								position: relative;
  // 								top: -1px;
  // 							}/*:before*/
  // 						}/*.message*/
  // 					}/*.new*/
  //
  // 					&:last-child {
  // 						border-bottom: 0px !important;
  // 					}
  //
  // 					img {
  // 						max-width: 28px;
  // 					}
  //
  // 				}/*.notification*/
  // 			}/*.notification-list*/
  // 		}
  //
  //   }/*.notifications*/
}
