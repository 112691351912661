.modal {
  &.create-file-categories {
    .title {
      h4 {
        font-size: 1.07142857rem;
        color: #74c0d0 !important;
      }
    }

    input[type="text"],
    textarea {
      color: #74c0d0;
      font-family: "Montserrat", sans-serif;
      // font-size: 1.14285714rem;
      font-size: 14px;
      padding: 8px 15px !important;
      border-radius: 18px;
      border: 1px solid rgba(34, 36, 38, 0.15);
      outline: 0px !important;
      &:active {
        border: 1px solid #74c0d0;
        background: #fafafa;
        -webkit-box-shadow: none;
        box-shadow: none;
      }
      &:focus {
        border: 1px solid #74c0d0;
        background: #fff;
        -webkit-box-shadow: none;
        box-shadow: none;
      }
      &.fluid {
        width: 100%;
      }
    }
    input[type="text"] {
      border-radius: 100px;
    }
    textarea::placeholder {
      opacity: 0.5;
    }
    .selection.dropdown {
      border-radius: 18px;
      border: 1px solid #74c0d0;
      background: transparent;
      color: #74c0d0;
      font-size: 14px;
      line-height: 1em;
      min-width: 1em;
      min-height: 2.71428571em;
      height: auto;
      &.multiple {
        .label {
          background: #74c0d0;
          border-radius: 10px;
          color: #ffffff;
          box-shadow: 0px 0px 0px;
        }
      } /*.multiple*/
    } /*input, dropdown*/
    .close {
      top: 1rem;
      right: 1rem;
      color: #999999;
    }
  } /*&.create-file-categories*/
}
