.ui {
  &.modal {
    box-shadow: 10px 10px 10px 10px rgba(0, 0, 0, 0.5);
    &.modal-feedback-report-bugs {
      background: #ffffff !important;
      color: #9b9b9b;
      font-size: 14px;
      overflow-x: hidden;
      overflow-y: auto;
      z-index: 1002 !important;

      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        color: #74c0d0;
        text-transform: unset;
      }

      .icono-close-border {
        color: #9b9b9b;
        position: absolute;
        top: 15px;
        right: 15px !important;
      }

      .content {
        background: transparent;
        color: #9b9b9b;
        padding: 0px !important;
        height: calc(100vh - 107px);

        input,
        textarea,
        button,
        .selection.dropdown {
          border-radius: 18px;
          border: 1px solid #74c0d0;
          background: transparent;
          color: #74c0d0;
          font-size: 14px;
          line-height: 1em;
          min-width: 1em;
          min-height: 2.71428571em;
          height: auto;
          padding: 0.78571429em 1em !important;
          outline: 0px !important;
        }

        textarea {
          line-height: 1.5em;
          resize: none;
        }
      } /*.content*/
      .ui.form .field .input i.icono,
      .ui.form .fields .field .input i.icono {
        position: absolute;
        top: 8px;
        right: 10px;
        height: 100%;
        width: 1em;
        border-radius: 0 0.28571429rem 0.28571429rem 0;
        -webkit-transition: opacity 0.3s ease;
        -o-transition: opacity 0.3s ease;
        transition: opacity 0.3s ease;
      }
    } /*.modal-feedback-report-bugs*/
  } /*.modal*/
} /*.ui*/
