//
//  Heto UI variables
//

// Main path
// Folder Images
$path-images: "../images/" !default;
// Fonts
$path-fonts: "fonts/skejule" !default;

// Colors:
$heto-color-blue: #3765b3 !default;
$heto-color-brown: #e79b1c !default;
$heto-color-teal: #74c0d0 !default;
$heto-color-gray: #999999 !default;
$heto-color-white: #ffffff !default;
$heto-color-red: #db2828 !default;
$heto-color-orange: #f2711c !default;
$heto-color-green: #21ba45 !default;

// Roles:
// SuperCompanyAdminstrators/CompanyAdminstrators
$heto-color-role-primary-default: #6ac7da;
$heto-color-role-secondary-default: #56ceaa;
$heto-color-role-rgba-default: rgba(116, 192, 208, 0.2);
// OTHER --> #2F7882 / #9EF1FE / #67C1CF
// Students
$heto-color-role-primary-students: #526d20;
$heto-color-role-secondary-students: #97b950;
$heto-color-role-rgba-students: rgba(82, 109, 32, 0.2);
// Coordinators, Faculties
$heto-color-role-primary-faculties: #875532;
$heto-color-role-secondary-faculties: #d59d78;
$heto-color-role-rgba-faculties: rgba(135, 85, 50, 0.2);
// InstitutionsAdministrators, Preceptors
$heto-color-role-primary-institutions: #59216b;
$heto-color-role-secondary-institutions: #9b48b7;
$heto-color-role-rgba-institutions: rgba(89, 33, 107, 0.2);
// OTHER --> #c970e5
