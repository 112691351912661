#root {
  #heto-app-website {
    @import "../../styles/partials/_all";
    @mixin set-home($role: "companies") {
      &.home {
        .body {
          @media (max-height: 580px) {
            .background-left-size {
              @include set-background-rgba($role);
            }
          }
          .welcome {
            @media (min-height: 581px) {
              @include set-background-rgba($role);
            }
          }
        }
      }
    }
    @mixin set-notifications($role: "companies") {
      $color-notifications: get-color-secondary($role);
      @if $role == "companies" {
        $color-notifications: get-color-primary($role);
      }
      &.notifications {
        .notifications-popup {
          .ui {
            &.secondary.pointing.menu {
              .item {
                background: #fff;
                &.active {
                  background: $color-notifications;
                }
              }
            }
            &.tab {
              &.pane {
                border: 5px solid $color-notifications !important;
                .notifications-list {
                  .notification {
                    border-bottom: 2px solid $color-notifications;

                    &:last-child {
                      border-bottom: 0px !important;
                    }
                  } /*.notification*/
                } /*.notification-list*/
              } /*.pane*/
            } /*.tab*/
          } /*.ui*/
        }
      }
    }
    #main {
      &.home {
        overflow: hidden;

        .body {
          width: 100vw;
          height: 100vh;
          position: absolute;
          z-index: 100;
          left: -100vw;
          top: 0px;
          background: #ffffff;
          padding-left: 0px;
          overflow-y: scroll;
          overflow-x: hidden;
          transition: all 0.5s ease-out 0s;
          -webkit-transition: all 0.5s ease-out 0s;
          -moz-transition: all 0.5s ease-out 0s;

          @media (min-width: 992px) {
            padding-left: 82px;
            overflow: hidden;
          }

          &.visible {
            left: 0px;
            transition: all 0.5s ease-out 0s;
            -webkit-transition: all 0.5s ease-out 0s;
            -moz-transition: all 0.5s ease-out 0s;
          }

          & > .ui.grid {
            height: 100vh;
          }

          .welcome {
            height: 100vh;
            @media (min-height: 581px) {
              background: rgba(116, 192, 208, 0.2);
            }
            @media (max-width: 768px) {
              height: auto;
              padding-bottom: 5px;
            }

            .row-top {
              .title {
                h1 {
                  margin: 0px;

                  &.name {
                    text-overflow: ellipsis;
                    white-space: normal;
                    overflow: hidden;

                    &:after {
                      content: "!";
                    }
                  }
                }
              }
              .list.submenu {
                .item {
                  font-size: 0.75rem;
                  &:first-child {
                    border-left: 0px;
                  }
                  &:last-child {
                    border-right: 0px;
                  }
                }
              }
            }
            .row-bottom {
              justify-content: center;
              @media only screen and (max-width: 767px) {
                .button {
                  padding-left: 45px !important;
                  padding-right: 45px !important;
                }
              }
            }
          } /*.welcome*/

          .main {
            padding: 0px;
            line-height: 14px;

            @media (min-width: 768px) {
              padding: 10px;
              line-height: 24px;
            }

            @media (min-width: 992px) {
              // padding: 6vh 4vw;
              line-height: 18px;
            }

            @media (min-width: 1200px) {
              // padding: 8vh 100px;
              line-height: 24px;
            }

            &.widgets {
              overflow-x: hidden;
              overflow-y: hidden;

              .widget {
                border-radius: 8px;
                font-size: 14px;
                height: 280px;
                @media (max-width: 769px) {
                  height: 260px;
                }

                .widget-header {
                  padding: 10px 15px;
                  color: #9fa9ba;
                  flex-grow: inherit;

                  .title {
                    // padding-right: 30px;
                    text-transform: uppercase;
                  }

                  a {
                    color: #9fa9ba;

                    .icono {
                      position: absolute;
                      top: 10px;
                      right: 15px;
                    }
                  }
                } /*.widget-header*/

                .widget-body {
                  padding: 10px 15px;
                  overflow-y: hidden;
                  width: 100%;
                  outline: none;

                  .message {
                    font-size: 14px;
                    color: #74c0d0;
                    letter-spacing: 0.23px;
                  }

                  &.valign {
                    padding: 5% 15px;
                  }
                } /*.widget-body*/

                .widget-footer {
                  min-height: auto !important;
                  span {
                    cursor: pointer;
                  }
                  .caselog-create-student {
                    .ui {
                      &.button {
                        &.skejule {
                          padding: 2px 10px !important;
                          font-size: 10px;
                          line-height: 16px;
                          background: #ffffff !important;
                          color: #6ac7da !important;
                          &:hover {
                            background: #6ac7da !important;
                            color: #ffffff !important;
                          }
                        }
                      }
                    } /*.ui*/
                  }
                } /*.widget-footer*/

                &.files {
                  &.admin {
                    height: 420px;
                    @media (min-height: 1000px) {
                      height: 320px;
                    }
                    &.only-one {
                      height: 420px;
                    }
                  }
                  &.not-admin {
                    .widget-body {
                      overflow-y: auto;
                      width: 100%;
                      height: 100%;
                    }
                  }
                  table {
                    border: 0px;
                    color: #999999;
                    letter-spacing: 0.3px;
                    font-size: 14px;
                    tr.sub-row-header th {
                      color: #3765b3;
                    }
                    tr.main-row-header th {
                      background: #6ac7da;
                      color: #fff;
                      line-height: 24px;
                    }
                    thead {
                      tr.sub-row-header th {
                        background: #ffffff;
                        border-left: 0px;
                        padding: 7px 0 7px 0.78571429em !important;
                        .th-cell {
                          white-space: nowrap;
                          text-overflow: ellipsis;
                          overflow: hidden;
                          float: left;
                          @media (max-width: 1280px) {
                            width: 75%;
                          }
                        }
                        &.asc-desc {
                          &:after {
                            display: inline-block;
                            content: "❮❯";
                            transform: rotate(-90deg);
                            transform-origin: center;
                            color: #9e9d9d;
                            position: relative;
                            top: 3px;
                          }
                        }
                        &.descending {
                          &:after {
                            content: "❯";
                            transform: rotate(90deg);
                            transform-origin: center;
                            color: #9e9d9d;
                            position: relative;
                            left: 10px;
                            top: 5px;
                          }
                        }
                        &.ascending {
                          &:after {
                            content: "❯";
                            transform: rotate(-90deg);
                            transform-origin: center;
                            color: #9e9d9d;
                            position: relative;
                            left: 3px;
                            top: 5px;
                          }
                        }
                      }
                    }
                    &.users {
                      display: block;
                      padding-right: 1px;
                      overflow-y: auto;
                      height: 250px;
                      &::-webkit-scrollbar {
                        width: 3px !important;
                        background: #dcdcdc !important;
                      }
                      &::-webkit-scrollbar-track {
                        background: #dcdcdc !important;
                      }
                      &::-webkit-scrollbar-thumb {
                        background: $heto-color-teal !important;
                      }
                    }
                    &.companies,
                    &.institutions {
                      tbody {
                        padding-right: 1px;
                        overflow-y: auto;
                        height: 250px;
                        &::-webkit-scrollbar {
                          width: 3px !important;
                          background: #dcdcdc !important;
                        }
                        &::-webkit-scrollbar-track {
                          background: #dcdcdc !important;
                        }
                        &::-webkit-scrollbar-thumb {
                          background: $heto-color-teal !important;
                        }
                      }
                    }
                    // .skejule-scroll {
                    //   height: calc(30vh - 100px);
                    //   min-height: 100px;
                    // }
                    tbody {
                      display: block;
                      tr {
                        &.soon-expired {
                          background-color: #fffaf3;
                          td.expiration-date {
                            color: #e79b1c;
                          }
                        }
                        &.expired {
                          background-color: #fff6f6;
                          td.expiration-date {
                            color: #d0021b;
                          }
                        }
                        td {
                          span,
                          div {
                            &.link {
                              color: #3765b3;
                              &:hover {
                                cursor: pointer;
                                text-decoration: underline;
                              }
                            }
                          }
                          &.tooltip:not(.expiration-date) {
                            &:after {
                              width: 175px;
                              right: 0;
                              left: 10px;
                            }
                          }
                          .icono.icono-download {
                            cursor: pointer;
                            font-size: 24px !important;
                            font-weight: bold;
                            position: relative;
                            bottom: 2px;
                            @media (min-width: 768px) {
                              float: right;
                            }
                          }
                        }
                      }
                    }
                  }
                  @media (min-width: 768px) {
                    table {
                      thead {
                        tr.sub-row-header th {
                          float: left;
                        }
                      }
                      tbody {
                        tr {
                          display: flex;
                          td {
                            padding: 7px 14px 7px 10px;
                          }
                        }
                      }
                      &.fixed-header {
                        thead,
                        thead tr,
                        tbody,
                        td,
                        th {
                          display: block;
                        }
                        thead tr th {
                          padding-left: 11px;
                          float: left;
                        }
                        tbody {
                          width: 100%;
                        }
                      }
                    }
                  }
                }
                &.files-admin {
                  .widget-body {
                    .tab {
                      overflow-y: auto;
                      width: 100%;
                      height: 100%;
                      table {
                        .skejule-scroll {
                          height: calc(30vh - 100px);
                          min-height: 100px;
                        }
                        thead {
                          tr th {
                            color: #9fa9ba;
                          }
                        }
                      }
                      @media (min-width: 768px) {
                        table {
                          tbody {
                            tr {
                              display: flex;
                              td {
                                padding: 11px;
                                border-top: 0px;
                              }
                            }
                          }
                          &.fixed-header {
                            thead,
                            thead tr,
                            tbody,
                            td,
                            th {
                              display: block;
                            }
                            thead tr th {
                              padding-left: 11px;
                              float: left;
                            }
                            tbody {
                              width: 100%;
                            }
                          }
                        }
                      }
                    }
                  }
                }

                &.evaluations,
                &.institution,
                &.rotation {
                  .widget-header {
                    flex: inherit;
                  }
                }

                &.case-logs,
                &.evaluations.answers,
                &.rotation {
                  .slick-slider {
                    span.iconos {
                      position: absolute;
                      top: 50%;
                      cursor: pointer;
                      z-index: 10;
                      span.text {
                        font-size: 12px;
                        opacity: 0.5;
                      }
                    }
                  }
                }

                &.rotation {
                  &.student {
                    .widget-body {
                      overflow-x: hidden;
                      .slick-slider .slick-track {
                        height: 175px;
                      }
                      @media (min-width: 769px) {
                        div.avatar {
                          padding-left: 10px;
                        }
                      }
                    }
                  }
                  &.institutions {
                    .segment.tab {
                      border: 0;
                    }
                    .slick-slider {
                      .slick-list {
                        .slick-slide {
                          float: right;
                        }
                      }
                      span.iconos {
                        top: 40%;
                      }
                      .namehandle {
                        .link {
                          color: #3765b3;
                          &:hover {
                            cursor: pointer;
                            text-decoration: underline;
                          }
                        }

                        .handle {
                          color: #d4bfbf;
                          font-weight: lighter;
                          margin-bottom: 5px;
                          text-overflow: ellipsis;
                          white-space: normal;
                          overflow: hidden;
                        }
                      }
                    }
                  }
                  .period {
                    .label {
                      font-weight: bold;
                      &.incourse {
                        color: #21ba45;
                      }
                      &.upcoming {
                        color: #f2711c;
                      }
                      &:after {
                        content: ": ";
                      }
                    }
                    .from {
                      &:after {
                        content: " - ";
                      }
                    }
                    .to {
                    }
                    @media (max-width: 1024px) {
                      font-size: 10px;
                    }
                  }
                }

                &.case-logs.company-admins {
                  .widget-body {
                    .slick-slider .slick-track {
                      .atom-meter {
                        @media (max-width: 1024px) {
                          .atom {
                            font-size: 20px;
                            width: 50px;
                            height: 50px;
                            line-height: 50px;
                            .electron {
                              width: 10px;
                              height: 10px;
                              right: -5px;
                            }
                          }
                          .atom-label {
                            font-size: 10px;
                          }
                        }
                      }
                    }
                  }
                }

                &.evaluations.answers {
                  .widget-body {
                    .atom-meter {
                      .atom {
                        border-radius: 45px;
                        width: 82px;
                        height: 82px;
                        font-size: 24px;
                        line-height: 80px;
                      }
                    }
                  }
                }

                &.half {
                  // height: calc(50% - 15px);
                  height: calc(19vh - 15px);
                }

                &.twitter {
                  font-size: 12px;

                  .namehandle {
                    line-height: 15px;
                    .name {
                      color: #74c0d0;
                      font-weight: bold;
                    }

                    .handle {
                      color: #d4bfbf;
                      font-weight: lighter;
                      margin-bottom: 5px;

                      &:before {
                        content: "@";
                      }
                    }
                  }

                  .tweetbody {
                    line-height: 18px;

                    hashtag {
                      color: #74c0d0;
                      margin-left: 5px;
                    }
                  }
                }
              } /*.widget*/
            } /*.widgets*/

            @media (min-width: 768px) {
              &.widgets {
                height: auto;
                overflow-y: hidden;
              }
            }
            @media (min-width: 992px) and (max-height: 1040px) {
              &.widgets {
                overflow-y: auto;
                height: 100vh;
                &::-webkit-scrollbar {
                  width: 5px !important;
                  background: #dcdcdc !important;
                }
                &::-webkit-scrollbar-track {
                  background: #dcdcdc !important;
                }
                &::-webkit-scrollbar-thumb {
                  background: $heto-color-teal !important;
                }
              }
            }
          } /*.main*/
        } /*.body*/

        .ui {
          &.button {
            &.skejule {
              padding: 5px 20px !important;
              color: #ffffff !important;
              background: #74c0d0 !important;
            }
          }
        } /*.ui*/
      } /*.dashboard*/
    } /*#main*/
    &.supercompanyadministrators,
    &.companyadministrators {
      #main {
        @include set-home("companies");
        @include set-notifications("companies");
      }
    }
    &.students,
    &.alumnis {
      #main {
        @include set-home("students");
        @include set-notifications("students");
      }
    }
    &.coordinators,
    &.faculties {
      #main {
        @include set-home("faculties");
        @include set-notifications("faculties");
      }
    }
    &.institutionadministrators,
    &.preceptors {
      #main {
        @include set-home("institutions");
        @include set-notifications("institutions");
      }
    }
  }
}

.heto-popup {
  &.widget.home {
    .widget {
      position: fixed;
      top: 20%;
      z-index: 1000;
      &.widget-weather {
        left: 40%;
        width: 45%;
        @media (max-width: 991px) {
          left: 25%;
          width: 55%;
        }
      }
      &.widget-calendar {
        left: 15%;
        width: 75%;
        @media (min-width: 580px) {
          left: 25%;
          width: 50%;
        }
        @media (min-width: 992px) {
          left: 45%;
          width: 40%;
        }
        @media (min-width: 1200px) {
          left: 47%;
          width: 30%;
        }
        @media (min-width: 1600px) {
          left: 50%;
          width: 20%;
        }
      }
      .header {
        font-size: 14px;
        color: #9fa9ba;
        padding: 10px 15px;
        border-bottom: 1px solid #e6eaee;
        position: relative;

        .title {
          padding-right: 30px;
          text-transform: uppercase;
        }

        a {
          color: #9fa9ba;

          .icono {
            position: absolute;
            top: 10px;
            right: 15px;
          }
        }
      } /*.header*/
      &.widget-calendar {
        .rw-calendar {
          border: 0px;
          font-size: 0.75em;
          box-shadow: 0px 0px 0px transparent;

          .rw-calendar-header {
            font-size: 14px;
            letter-spacing: 1.14px;

            .rw-calendar-btn-left,
            .rw-calendar-btn-right {
              color: #74c0d0;
              position: absolute;
              z-index: 10;
              .rw-i {
                font-weight: bold;
                font-size: 16px;
              }
            }

            .rw-calendar-btn-left {
              left: 0px;
            }

            .rw-calendar-btn-right {
              right: 0px;
            }

            .rw-calendar-btn-view {
              color: #957e7e;
              width: 100%;
              font-weight: bold;
            }
          } /*.rw-calendar-header*/

          .rw-calendar-transition-group {
            .rw-cell,
            table tr th {
              color: #846e6e;
            }

            .rw-cell-off-range {
              color: #d4bfbf;
            }

            .rw-cell {
              border-radius: 35px;
              line-height: unset;
              padding: 1.25em;
              &.rw-state-selected {
                background-color: transparent;
                border-color: #74c0d0;
              }
              &.rw-now {
                background: #74c0d0;
                color: #ffffff;
                border: 0px;
              }
            }
          } /*.rw-calendar-transition-group*/
        } /*.rw-calendar*/
      }
    }
  }
}
// NOTE: We need to refactor this class and checking in other parts the app
.sidebar-filters {
  padding: 20px 30px;

  &.skejule-scroll {
    height: 100%;
    overflow-x: hidden;
  }

  .ui {
    &.container {
      margin-top: 10px;
      // margin-bottom: 20px;
      display: inline-block;

      .selection {
        &.dropdown {
          border-radius: 18px;
          border: 1px solid #74c0d0;
          // background: transparent;
          color: #74c0d0;
          display: inline-block;
          // padding: 4px 3.5em 4px 1em !important;
          float: left;
          line-height: 2em;

          &:not(.button) {
            & > .default {
              &.text {
                color: rgba(116, 192, 208, 0.3);
              }
            }
          }

          & > .dropdown {
            &.icon {
              top: unset;
              bottom: 0.78571429em;
              right: 20px;
              padding: 10px;
              border-radius: 10px;
            }
          }

          &.period {
            padding: 4px 3.5em 4px 1em !important;
          }

          &.multiple {
            .label {
              background: #74c0d0;
              border-radius: 10px;
              color: #ffffff;
              box-shadow: 0px 0px 0px;
            }

            &.fluid {
              .label {
                // width: 100%;

                .icon {
                  float: right;
                }
              }
            } /*.fluid*/

            &.search {
              & > input {
                &.search {
                  font-size: 1em;
                  padding: 0.35714286em 0.78571429em !important;
                  margin: 0.14285714rem 0.28571429rem 0.14285714rem 0;
                  height: 24px;
                  min-height: 24px;
                }
              }
            } /*.search*/
          } /*.multiple*/
        } /*.dropdown*/
      } /*.selection*/

      .rw-datetime-picker {
        input {
          width: 11em;
          min-width: 11em;
          padding: 7px 10px !important;
        }

        & > .rw-widget-container {
          background-color: transparent;
          border-radius: 18px;
          border: 1px solid #74c0d0;
          color: #74c0d0;
          box-shadow: 0px 0px 0px !important;
          width: auto;

          & > .rw-select {
            position: absolute;
            top: 0px;
            right: 52px;

            &:hover,
            :active,
            :focus {
              background: transparent !important;
              box-shadow: 0px 0px 0px !important;
            }

            &.rw-select-bordered {
              border-left: 1px solid #74c0d0;
            }

            .rw-btn-select {
              color: #74c0d0;
              opacity: 1;
              width: 53px;
              // height: 38px;
              padding-top: 0px !important;
              padding-bottom: 0px !important;
              border-radius: 0px 18px 18px 0px;
              overflow: hidden;
              border: 0px;

              &:hover {
                background: #74c0d0 !important;
                color: #ffffff !important;
              }
            } /*.rw-btn-select*/
          } /*> .rw-select*/
        } /*.rw-widget-container*/

        .rw-popup-container {
          background: #ffffff;
          border-radius: 3px;
          border: 2px solid rgba(0, 0, 0, 0.1);

          .rw-popup-transition {
            padding: 0px;
            margin: 0px;

            button {
              padding: 5px 10px !important;
            }

            .rw-calendar {
              padding: 20px;
            }

            .rw-calendar-header {
              font-size: 18px;
              letter-spacing: 1.14px;

              .rw-calendar-btn-left,
              .rw-calendar-btn-right {
                color: #74c0d0 !important;
                position: absolute;
                z-index: 10;
                padding: 0px 5px !important;
              }

              .rw-calendar-btn-left {
                left: 0px;
              }

              .rw-calendar-btn-right {
                right: 0px;
              }

              .rw-calendar-btn-view {
                color: #846e6e !important;
                width: 100%;
                padding: 0px 20px !important;
              }
            } /*.rw-calendar-header*/

            .rw-calendar-transition-group {
              .rw-cell-off-range {
                color: #d4bfbf !important;
              }

              .rw-cell,
              table tr th {
                color: #846e6e !important;
              }

              .rw-cell {
                &.rw-now {
                  background: transparent !important;
                  border-radius: 100px !important;
                  border: 1px solid #74c0d0;
                }
                &.rw-state-selected {
                  background: #74c0d0 !important;
                  color: #ffffff !important;
                  border-radius: 100px !important;
                  border: 0px !important;
                }
              }
            } /*.rw-calendar-transition-group*/

            .rw-calendar-footer {
            } /*.rw-calendar-footer*/
          } /*.rw-popup-transition*/
        } /*.rw-popup-container */
      } /*.rw-datetime-picker*/

      &.range-date,
      &.begin-date,
      &.end-date {
        .clear-date {
          padding: 0px 5px;
          a {
            cursor: pointer;
            font-size: 11px;
            font-weight: bold;
            color: #bdbbbb;
            letter-spacing: 0.3px;
          }
          i.icono {
            font-size: 14px !important;
            color: #767676;
          }
          &.disabled {
            opacity: 0.5;
            a {
              cursor: default;
            }
            i.icono {
              color: #bdbbbb;
            }
          }
        }
      }
    } /*.container*/
  } /*.ui*/
} /*.sidebar-filters*/
