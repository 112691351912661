#root #heto-app-website {
  #main {
    @media (min-width: 768px) {
      padding-left: 78px;
    }
  }
}
/******************* BROADCAST MODAL MESSAGE ********************/
.ui.modal.broadcast-message-modal-view {
  .actions {
    button.ui.mini.button.basic {
      margin-right: 0.75em;
    }
    .ui.checkbox > label {
      color: #999;
      margin-right: 0.75em;
    }
  }
}
/******************* BROADCAST MODAL MESSAGE ********************/
/******************* BROADCAST POPUP MESSAGE ********************/
#broadcast-message-popup {
  &.ui.top {
    top: auto !important;
  }
  &.ui.bottom {
    bottom: auto !important;
  }
  min-width: 80%;
  max-width: 350px;
  .info-elements {
    width: auto;
    &.skejule-scroll {
      height: 30vh;
      border: 0;
    }
    .value {
      color: #999;
      padding: 5px;
    }
  }
}
/******************* BROADCAST POPUP MESSAGE ********************/
