#root #heto-app-website {
  @import "../../styles/partials/_all";
  /******************* TOP MOBILE MENU BAR ********************/
  .menubar {
    position: fixed;
    width: 100%;
    height: 50px;
    top: 0px;
    left: 0px;
    z-index: 999;
    @include set-background-menubar("companies");
    .trigger {
      top: 0px;
      left: 0px;
      position: absolute;
      padding: 9px 24px;
      cursor: pointer;
    }
    .search,
    .back {
      top: 0px;
      right: 0px;
      position: absolute;
      padding: 9px 24px;
      cursor: pointer;
    }
    .title {
      color: #ffffff !important;
      text-align: center !important;
      font-size: 20px !important;
      letter-spacing: 0.3px !important;
      text-transform: uppercase;
      font-weight: bold;
      line-height: 50px;
    }
  }
  /******************* TOP MOBILE MENU BAR ********************/
  /******************* SIDEBAR MENU ********************/
  .sidebarmenu {
    width: 82px;
    height: 100vh;
    position: fixed;
    z-index: 990;
    left: -82px;
    top: 0px;
    @include set-background-sidebarmenu("companies");
    transition: all 0.5s ease-out 0s;
    -webkit-transition: all 0.5s ease-out 0s;
    -moz-transition: all 0.5s ease-out 0s;

    &.visible {
      left: 0px;
      transition: all 0.5s ease-out 0s;
      -webkit-transition: all 0.5s ease-out 0s;
      -moz-transition: all 0.5s ease-out 0s;
    }

    @media (min-width: 769px) {
      left: 0px !important;
    }

    .ui {
      &.menu {
        .item {
          background: none;
          padding: 0px;
          margin: 0px 13px;
          min-width: 32px !important;
          border-top: 1px solid transparent;
          border-bottom: 1px solid transparent;
          border-radius: 0px;
          -moz-border-radius: 0px;
          -webkit-border-radius: 0px;
          cursor: pointer;
          .label {
            background: #fff;
            color: #8fcbd8;
          }
          &:first-child {
            margin-top: 50px;
          }
          &:before {
            background: none;
          }
          &.active {
            border-top: 1px solid #ffffff;
            border-bottom: 1px solid #ffffff;
            cursor: default;
            &.manager,
            &.notifications {
              cursor: pointer;
            }
          }
        }
      }
    }

    .user-avatar {
      position: absolute;
      left: 50%;
      width: 70px;
      height: 70px;
      // @media (max-width: 769px) {
      //   bottom: 40px !important;
      // }

      .dropdown-menu-user-avatar {
        left: 4px;
        .divider {
          margin: 0 !important;
        }

        .ui.simple.dropdown span,
        .item {
          line-height: unset !important;
          img,
          .image {
            margin-top: 0 !important;
            margin-bottom: 0 !important;
            margin-right: 14px;
            width: 27px;
            &.user-logged-in {
              margin-right: 10px;
            }
          }
          i.icon {
            margin-left: 4px;
            margin-right: 21px;
          }
        }
        .ui.simple.dropdown {
          span .image.avatar {
            margin-right: 0.49rem;
            max-height: 2em;
          }
          .dropdown.icon {
            margin-top: 7px;
          }
        }
      }
    }
  }
  &.supercompanyadministrators,
  &.companyadministrators {
    .menubar {
      @include set-background-menubar("companies");
    }
    .sidebarmenu {
      @include set-background-sidebarmenu("companies");
    }
  }
  &.students,
  &.alumnis {
    .menubar {
      @include set-background-menubar("students");
    }
    .sidebarmenu {
      @include set-background-sidebarmenu("students");
    }
  }
  &.coordinators,
  &.faculties {
    .menubar {
      @include set-background-menubar("faculties");
    }
    .sidebarmenu {
      @include set-background-sidebarmenu("faculties");
    }
  }
  &.institutionadministrators,
  &.preceptors {
    .menubar {
      @include set-background-menubar("institutions");
    }
    .sidebarmenu {
      @include set-background-sidebarmenu("institutions");
    }
  }
  /******************* SIDEBAR MENU ********************/
}

.fullscreen {
  &.modal {
    &.seriously-fullscreen {
      width: 100vw !important;
      height: 100vh !important;
      margin: 0 auto;
      margin-top: 0px;
      box-shadow: 0px 0px 0px !important;
      border-radius: 0px !important;
      padding: 0px !important;
      z-index: 1000 !important;

      .content {
        padding: 0px !important;

        .filters {
          margin-top: 50px;
          padding-top: 25px;
          overflow: auto;
          .accordion {
            height: auto;
          }
        }
      }
    }
  }
}
