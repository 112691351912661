/******************* HELPER CLASSES ********************/

.img-responsive img {
  height: auto;
  max-width: 100%;
  width: 100%;
}

.vcenter {
  position: relative !important;
  top: 50% !important;
  transform: translateY(20%) !important;
}

.nopadding {
  padding: 0px !important;
}

.nomargin {
  margin: 0px !important;
}

.noborder {
  border: 0px !important;
}

.nobg {
  background: transparent !important;
  background-image: none !important;
}

.noshadow {
  box-shadow: 0px 0px 0px !important;
}

.notitle > h1,
.notitle > h2,
.notitle > h3,
.notitle > h4,
.notitle > h5,
.notitle > h6 {
  display: none !important;
}

.hidden {
  display: none !important;
}

.visible {
  display: block !important;
}

.fixed {
  position: fixed !important;
}

.absolute {
  position: absolute !important;
}

.relative {
  position: relative !important;
}

.top {
  top: 0px !important;
}

.right {
  float: right !important;
  right: 0px !important;
}

.bottom {
  bottom: 0px !important;
}

.left {
  float: left !important;
  left: 0px !important;
}

.nofloat {
  float: none !important;
}

.transparent {
  opacity: 0 !important;
}

.rounded,
.rounded img {
  border-radius: 4px !important;
  -moz-border-radius: 4px !important;
  -webkit-border-radius: 4px !important;
}

.inlineb {
  display: inline-block !important;
}

.block {
  display: block !important;
}

.noposition {
  position: unset !important;
}

.text-left {
  text-align: left !important;
}

.text-center {
  text-align: center !important;
}

.text-right {
  text-align: right !important;
}

.text-justify {
  text-align: justify;
}

.text-italic {
  font-style: italic;
}

.bold {
  font-weight: bold !important;
}

.uppercase {
  text-transform: uppercase !important;
}

.capitalize {
  text-transform: capitalize !important;
}

.fullwidth {
  width: 100% !important;
  display: block;
}

.ellipsed {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.helper-text {
  font-size: 0.85em;
  font-style: italic;
}

.rotate90 {
  display: block;
  transform: rotate(90deg);
  transform-origin: center;
}

.rotate180 {
  display: block;
  transform: rotate(180deg);
  transform-origin: center;
}

.rotate270 {
  display: block;
  transform: rotate(270deg);
  transform-origin: center;
}

/******************* HELPER CLASSES ********************/

/******************* ARROWS ********************/

.arrow-up {
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;

  border-bottom: 8px solid #74c0d0;
}

.arrow-down {
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;

  border-top: 8px solid #74c0d0;
}

.arrow-right {
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-left: 8px solid #74c0d0;
}

/******************* ARROWS ********************/

/************* BORDER RADIUS HELPER CLASSES *********/

.br0 {
  border-radius: 0px !important;
  -moz-border-radius: 0px !important;
  -webkit-border-radius: 0px !important;
  overflow: hidden !important;
}

.br3 {
  border-radius: 3px !important;
  -moz-border-radius: 3px !important;
  -webkit-border-radius: 3px !important;
  overflow: hidden !important;
}

.br5 {
  border-radius: 5px !important;
  -moz-border-radius: 5px !important;
  -webkit-border-radius: 5px !important;
  overflow: hidden !important;
}

.br10 {
  border-radius: 10px !important;
  -moz-border-radius: 10px !important;
  -webkit-border-radius: 10px !important;
  overflow: hidden !important;
}

/************* BORDER RADIUS HELPER CLASSES *********/

/************* MARGIN & PADDING HELPER CLASSES *********/

/* MARGIN */
.m0 {
  margin: 0px !important;
}
.m1 {
  margin: 1px !important;
}
.m2 {
  margin: 2px !important;
}
.m3 {
  margin: 3px !important;
}
.m4 {
  margin: 4px !important;
}
.m5 {
  margin: 5px !important;
}
.m10 {
  margin: 10px !important;
}
.m15 {
  margin: 15px !important;
}
.m20 {
  margin: 20px !important;
}
.m25 {
  margin: 25px !important;
}
.m30 {
  margin: 30px !important;
}
.m40 {
  margin: 40px !important;
}
.m50 {
  margin: 50px !important;
}
.m100 {
  margin: 100px !important;
}
.m150 {
  margin: 150px !important;
}
.m200 {
  margin: 200px !important;
}

.mt0 {
  margin-top: 0px !important;
}
.mt1 {
  margin-top: 1px !important;
}
.mt2 {
  margin-top: 2px !important;
}
.mt3 {
  margin-top: 3px !important;
}
.mt4 {
  margin-top: 4px !important;
}
.mt5 {
  margin-top: 5px !important;
}
.mt10 {
  margin-top: 10px !important;
}
.mt15 {
  margin-top: 15px !important;
}
.mt20 {
  margin-top: 20px !important;
}
.mt25 {
  margin-top: 25px !important;
}
.mt30 {
  margin-top: 30px !important;
}
.mt40 {
  margin-top: 40px !important;
}
.mt50 {
  margin-top: 50px !important;
}
.mt100 {
  margin-top: 100px !important;
}
.mt150 {
  margin-top: 150px !important;
}
.mt200 {
  margin-top: 200px !important;
}

.mr0 {
  margin-right: 0px !important;
}
.mr1 {
  margin-right: 1px !important;
}
.mr2 {
  margin-right: 2px !important;
}
.mr3 {
  margin-right: 3px !important;
}
.mr4 {
  margin-right: 4px !important;
}
.mr5 {
  margin-right: 5px !important;
}
.mr10 {
  margin-right: 10px !important;
}
.mr15 {
  margin-right: 15px !important;
}
.mr20 {
  margin-right: 20px !important;
}
.mr25 {
  margin-right: 25px !important;
}
.mr30 {
  margin-right: 30px !important;
}
.mr40 {
  margin-right: 40px !important;
}
.mr50 {
  margin-right: 50px !important;
}
.mr100 {
  margin-right: 100px !important;
}
.mr150 {
  margin-right: 150px !important;
}
.mr200 {
  margin-right: 200px !important;
}

.mb0 {
  margin-bottom: 0px !important;
}
.mb1 {
  margin-bottom: 1px !important;
}
.mb2 {
  margin-bottom: 2px !important;
}
.mb3 {
  margin-bottom: 3px !important;
}
.mb4 {
  margin-bottom: 4px !important;
}
.mb5 {
  margin-bottom: 5px !important;
}
.mb10 {
  margin-bottom: 10px !important;
}
.mb15 {
  margin-bottom: 15px !important;
}
.mb20 {
  margin-bottom: 20px !important;
}
.mb25 {
  margin-bottom: 25px !important;
}
.mb30 {
  margin-bottom: 30px !important;
}
.mb40 {
  margin-bottom: 40px !important;
}
.mb50 {
  margin-bottom: 50px !important;
}
.mb100 {
  margin-bottom: 100px !important;
}
.mb150 {
  margin-bottom: 150px !important;
}
.mb200 {
  margin-bottom: 200px !important;
}

.ml0 {
  margin-left: 0px !important;
}
.ml1 {
  margin-left: 1px !important;
}
.ml2 {
  margin-left: 2px !important;
}
.ml3 {
  margin-left: 3px !important;
}
.ml4 {
  margin-left: 4px !important;
}
.ml5 {
  margin-left: 5px !important;
}
.ml10 {
  margin-left: 10px !important;
}
.ml15 {
  margin-left: 15px !important;
}
.ml20 {
  margin-left: 20px !important;
}
.ml25 {
  margin-left: 25px !important;
}
.ml30 {
  margin-left: 30px !important;
}
.ml40 {
  margin-left: 40px !important;
}
.ml50 {
  margin-left: 50px !important;
}
.ml100 {
  margin-left: 100px !important;
}
.ml150 {
  margin-left: 150px !important;
}
.ml200 {
  margin-left: 200px !important;
}

.mt1n {
  margin-top: -1px !important;
}
.mt2n {
  margin-top: -2px !important;
}
.mt3n {
  margin-top: -3px !important;
}
.mt4n {
  margin-top: -4px !important;
}
.mt5n {
  margin-top: -5px !important;
}
.mt10n {
  margin-top: -10px !important;
}
.mt15n {
  margin-top: -15px !important;
}
.mt20n {
  margin-top: -20px !important;
}
.mt25n {
  margin-top: -25px !important;
}
.mt30n {
  margin-top: -30px !important;
}
.mt40n {
  margin-top: -40px !important;
}
.mt50n {
  margin-top: -50px !important;
}
.mt100n {
  margin-top: -100px !important;
}
.mt150n {
  margin-top: -150px !important;
}
.mt200n {
  margin-top: -200px !important;
}

.mr1n {
  margin-right: -1px !important;
}
.mr2n {
  margin-right: -2px !important;
}
.mr3n {
  margin-right: -3px !important;
}
.mr4n {
  margin-right: -4px !important;
}
.mr5n {
  margin-right: -5px !important;
}
.mr10n {
  margin-right: -10px !important;
}
.mr15n {
  margin-right: -15px !important;
}
.mr20n {
  margin-right: -20px !important;
}
.mr25n {
  margin-right: -25px !important;
}
.mr30n {
  margin-right: -30px !important;
}
.mr40n {
  margin-right: -40px !important;
}
.mr50n {
  margin-right: -50px !important;
}
.mr100n {
  margin-right: -100px !important;
}
.mr150n {
  margin-right: -150px !important;
}
.mr200n {
  margin-right: -200px !important;
}

.mb1n {
  margin-bottom: -1px !important;
}
.mb2n {
  margin-bottom: -2px !important;
}
.mb3n {
  margin-bottom: -3px !important;
}
.mb4n {
  margin-bottom: -4px !important;
}
.mb5n {
  margin-bottom: -5px !important;
}
.mb10n {
  margin-bottom: -10px !important;
}
.mb15n {
  margin-bottom: -15px !important;
}
.mb20n {
  margin-bottom: -20px !important;
}
.mb25n {
  margin-bottom: -25px !important;
}
.mb30n {
  margin-bottom: -30px !important;
}
.mb40n {
  margin-bottom: -40px !important;
}
.mb50n {
  margin-bottom: -50px !important;
}
.mb100n {
  margin-bottom: -100px !important;
}
.mb150n {
  margin-bottom: -150px !important;
}
.mb200n {
  margin-bottom: -200px !important;
}

.ml1n {
  margin-left: -1px !important;
}
.ml2n {
  margin-left: -2px !important;
}
.ml3n {
  margin-left: -3px !important;
}
.ml4n {
  margin-left: -4px !important;
}
.ml5n {
  margin-left: -5px !important;
}
.ml10n {
  margin-left: -10px !important;
}
.ml15n {
  margin-left: -15px !important;
}
.ml20n {
  margin-left: -20px !important;
}
.ml25n {
  margin-left: -25px !important;
}
.ml30n {
  margin-left: -30px !important;
}
.ml40n {
  margin-left: -40px !important;
}
.ml50n {
  margin-left: -50px !important;
}
.ml100n {
  margin-left: -100px !important;
}
.ml150n {
  margin-left: -150px !important;
}
.ml200n {
  margin-left: -200px !important;
}

/* PADDING */
.p0 {
  padding: 0px !important;
}
.p1 {
  padding: 1px !important;
}
.p2 {
  padding: 2px !important;
}
.p3 {
  padding: 3px !important;
}
.p4 {
  padding: 4px !important;
}
.p5 {
  padding: 5px !important;
}
.p10 {
  padding: 10px !important;
}
.p15 {
  padding: 15px !important;
}
.p20 {
  padding: 20px !important;
}
.p25 {
  padding: 25px !important;
}
.p30 {
  padding: 30px !important;
}
.p40 {
  padding: 40px !important;
}
.p50 {
  padding: 50px !important;
}
.p100 {
  padding: 100px !important;
}
.p150 {
  padding: 150px !important;
}
.p200 {
  padding: 200px !important;
}

.pt0 {
  padding-top: 0px !important;
}
.pt1 {
  padding-top: 1px !important;
}
.pt2 {
  padding-top: 2px !important;
}
.pt3 {
  padding-top: 3px !important;
}
.pt4 {
  padding-top: 4px !important;
}
.pt5 {
  padding-top: 5px !important;
}
.pt10 {
  padding-top: 10px !important;
}
.pt15 {
  padding-top: 15px !important;
}
.pt20 {
  padding-top: 20px !important;
}
.pt25 {
  padding-top: 25px !important;
}
.pt30 {
  padding-top: 30px !important;
}
.pt40 {
  padding-top: 40px !important;
}
.pt50 {
  padding-top: 50px !important;
}
.pt100 {
  padding-top: 100px !important;
}
.pt150 {
  padding-top: 150px !important;
}
.pt200 {
  padding-top: 200px !important;
}

.pr0 {
  padding-right: 0px !important;
}
.pr1 {
  padding-right: 1px !important;
}
.pr2 {
  padding-right: 2px !important;
}
.pr3 {
  padding-right: 3px !important;
}
.pr4 {
  padding-right: 4px !important;
}
.pr5 {
  padding-right: 5px !important;
}
.pr10 {
  padding-right: 10px !important;
}
.pr15 {
  padding-right: 15px !important;
}
.pr20 {
  padding-right: 20px !important;
}
.pr25 {
  padding-right: 25px !important;
}
.pr30 {
  padding-right: 30px !important;
}
.pr40 {
  padding-right: 40px !important;
}
.pr50 {
  padding-right: 50px !important;
}
.pr100 {
  padding-right: 100px !important;
}
.pr150 {
  padding-right: 150px !important;
}
.pr200 {
  padding-right: 200px !important;
}

.pb0 {
  padding-bottom: 0px !important;
}
.pb1 {
  padding-bottom: 1px !important;
}
.pb2 {
  padding-bottom: 2px !important;
}
.pb3 {
  padding-bottom: 3px !important;
}
.pb4 {
  padding-bottom: 4px !important;
}
.pb5 {
  padding-bottom: 5px !important;
}
.pb10 {
  padding-bottom: 10px !important;
}
.pb15 {
  padding-bottom: 15px !important;
}
.pb20 {
  padding-bottom: 20px !important;
}
.pb25 {
  padding-bottom: 25px !important;
}
.pb30 {
  padding-bottom: 30px !important;
}
.pb40 {
  padding-bottom: 40px !important;
}
.pb50 {
  padding-bottom: 50px !important;
}
.pb100 {
  padding-bottom: 100px !important;
}
.pb150 {
  padding-bottom: 150px !important;
}
.pb200 {
  padding-bottom: 200px !important;
}

.pl0 {
  padding-left: 0px !important;
}
.pl1 {
  padding-left: 1px !important;
}
.pl2 {
  padding-left: 2px !important;
}
.pl3 {
  padding-left: 3px !important;
}
.pl4 {
  padding-left: 4px !important;
}
.pl5 {
  padding-left: 5px !important;
}
.pl10 {
  padding-left: 10px !important;
}
.pl15 {
  padding-left: 15px !important;
}
.pl20 {
  padding-left: 20px !important;
}
.pl25 {
  padding-left: 25px !important;
}
.pl30 {
  padding-left: 30px !important;
}
.pl40 {
  padding-left: 40px !important;
}
.pl50 {
  padding-left: 50px !important;
}
.pl100 {
  padding-left: 100px !important;
}
.pl150 {
  padding-left: 150px !important;
}
.pl200 {
  padding-left: 200px !important;
}

/************* MARGIN & PADDING HELPER CLASSES *********/

/************* COLOR & BACKGROUND CLASSES *********/
.bg-heto-teal {
  background-color: $heto-color-teal;
  color: white;
}

.color-heto-teal {
  color: $heto-color-teal;
}
