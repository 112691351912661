// Index SASS

// TODO: RE Write whole files
html,
body,
div#root,
main,
main > div {
  // height: 100%;
}
body {
  margin: 0;
  padding: 0;
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  color: #999999;
  letter-spacing: 0.3px;
  line-height: 24px;
  background: transparent url($path-images + "fondo.png") no-repeat center
    center;
  background-size: cover;
}

// .bgoverlayOLD {
//   width: 100vw;
//   height: 100vh;
//   position: absolute;
//   left: 0;
//   top: 0;
//   z-index: -1;
//   background: #9ed1ff;
//   background: -webkit-radial-gradient(#9ed1ff, #285bb1);
//   background: -o-radial-gradient(#9ed1ff, #285bb1);
//   background: -moz-radial-gradient(#9ed1ff, #285bb1);
//   background: radial-gradient(#9ed1ff, #285bb1);
//   opacity: 0.86;
// }

.bgoverlay {
  width: 100vw;
  height: 100vh;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 999;
  opacity: 0.5;
  background: rgba(0, 0, 0, 0.5);
}

button,
input,
optgroup,
select,
textarea {
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 400;
  color: #999999;
  letter-spacing: 0;
  line-height: 24px;
  margin-bottom: 20px;
  padding: 12px 20px !important;
}

textarea {
  line-height: 1.5em;
  resize: none;
}

input,
textarea {
  &:focus {
    background: #fff;
    &::placeholder {
      opacity: 0.5;
    }
  }
}

.fluid {
  display: flex;
}

.ui {
  &.grid {
    height: 100%;
    & > .row {
      padding: 0px;

      & > .column {
        padding: 1rem;
      }
    }
  }

  &.modal {
    padding: 20px;
    border-radius: 8px;
    -moz-border-radius: 8px;
    -webkit-border-radius: 8px;
    -o-border-radius: 8px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);

    & > .header {
      text-align: center;
      border-bottom: 0px;

      .actions {
        color: #9b9b9b;
        position: absolute;
        top: 15px !important;
        right: 15px !important;
      }
    }
    h1 {
      font-size: 33px;
      color: #c89065;
      letter-spacing: 1.33px;
      line-height: 40px;
      text-transform: uppercase;
      text-align: center;
    }
    h4 {
      font-size: 18px;
      color: #3765b3;
      letter-spacing: 0.3px;
      line-height: 16px;
      margin-top: 0px;
      text-align: center;
    }
    &.fullscreen.modal.seriously-fullscreen {
      top: 0;
      margin: 0;
      width: 100% !important;
    }

    @media (max-width: 767px) {
      &.seriously-fullscreen-mobile {
        width: 100vw !important;
        height: 100vh !important;
        margin: 0 auto;
        margin-top: 0px;
        box-shadow: 0px 0px 0px !important;
        border-radius: 0px !important;
        padding: 0px !important;
        z-index: 1000 !important;
        left: 0 !important;
        padding: 10px 20px !important;
      }
    }
  }

  @media (min-width: 768px) {
    &.modal {
      padding: 45px 85px;
    }
  }

  &.input,
  &.button,
  &button {
    font-family: "Montserrat", sans-serif;
    font-size: 18px;
    font-weight: 400;
    color: #999999;
    letter-spacing: 0;
    line-height: 24px;
    margin-bottom: 15px;
  }
  &.skejule {
    &.button,
    &.buttons button {
      background-color: #3765b3;
      color: #fff;
      text-shadow: none;
      background-image: none;
    }
  }
}

// /******************* GENERIC CSS ********************/
#main,
#modal {
  .go-back-button {
    border-radius: 14px;
    -moz-border-radius: 14px;
    -webkit-border-radius: 14px;
    background: transparent;
    padding: 0 !important;
    margin: 10px 0;
    font-size: 19px !important;
    line-height: 32px;

    &:hover,
    &:focus {
      background: transparent;
    }
  }

  button {
    &.titlebutton {
      // padding: 0px 20px !important;
      // line-height: 28px;
      border: 1px solid #74c0d0;
      // height: 28px;
      margin-left: 15px;
      margin-right: 0px;
      margin-top: 0px;
      margin-bottom: 0px;

      &.upload-data-pd {
        padding: 0px 15px !important;
      }

      @media (min-width: 768px) {
        margin-top: 15px;
        margin-bottom: 15px;
      }

      @media (min-width: 992px) {
        margin-left: 35px;
        margin-right: 0px;
        margin-top: 5px;
      }

      span {
        // position: relative;
        // top: -2px;

        &.label {
          color: #74c0d0;
          letter-spacing: 0;
          line-height: 14px;
        }
        &.invitations-count {
          font-size: 9px;
          color: #9e9d9d;
          letter-spacing: 0;
          line-height: 11px;
          margin-left: 3px;
        }
        &.invitations-symbol {
          font-size: 9px;
          color: #d74a52;
          letter-spacing: 0;
          line-height: 11px;
          margin-left: 1px;
        }
      }
    }
  }
}
// /******************* GENERIC CSS ********************/

@include skejule-teal("companies");
@include skejule-scroll("companies");
@include H1-6("companies");
@include ui-button-skejule("companies");

#root {
  #heto-app-website {
    // @include skejule-teal('companies');
    // @include skejule-scroll('companies');
    // @include H1-6('companies');
    // /******************* CUSTOM BUTTONS ********************/
    // @include ui-button-skejule('companies');
    // /******************* CUSTOM BUTTONS ********************/
    // &.students {
    //   @include skejule-teal('students');
    //   @include skejule-scroll('students');
    //   @include H1-6('students');
    //   /******************* CUSTOM BUTTONS ********************/
    //   @include ui-button-skejule('students');
    //   /******************* CUSTOM BUTTONS ********************/
    // }
    // &.faculties {
    //   @include skejule-teal('faculties');
    //   @include skejule-scroll('faculties');
    //   @include H1-6('faculties');
    //   /******************* CUSTOM BUTTONS ********************/
    //   @include ui-button-skejule('faculties');
    //   /******************* CUSTOM BUTTONS ********************/
    // }
    // &.institutionadministrators,
    // &.preceptors {
    //   @include skejule-teal('institutions');
    //   @include skejule-scroll('institutions');
    //   @include H1-6('institutions');
    //   /******************* CUSTOM BUTTONS ********************/
    //   @include ui-button-skejule('institutions');
    //   /******************* CUSTOM BUTTONS ********************/
    // }
    .skejule-blue {
      color: $heto-color-blue !important;
    }
    .skejule-brown {
      color: $heto-color-brown !important;
    }
    .skejule-gray {
      color: $heto-color-gray !important;
    }
    .white {
      color: $heto-color-white !important;
    }
    .red {
      color: $heto-color-red !important;
    }
    .orange {
      color: $heto-color-orange !important;
    }
    .green {
      color: $heto-color-green !important;
    }
  }
}

/******************* CUSTOM TOGGLE ********************/

.ui {
  &.toggle {
    &.checkbox {
      &.skejule {
        margin-top: 2px;

        input:checked ~ .box::before,
        input:checked ~ label::before {
          background-color: #74c0d0 !important;
        }

        input:checked ~ .box,
        input:checked ~ label {
          color: #999999 !important;
        }

        .box::after,
        label::after {
          width: 17px;
          height: 17px;
          top: 2px;
          left: 2px;
        }

        label {
          color: #999999 !important;
        }

        &.flipped {
          .box,
          label {
            padding-right: 4.5rem;
            padding-left: 0rem;
          }

          .box::before,
          label::before {
            left: unset;
            right: 0px;
          }

          .box::after,
          label::after {
            right: 2.15rem;
            left: unset;
          }

          input:checked ~ .box::after,
          input:checked ~ label::after {
            right: 0.15rem;
            left: unset;
          }
        }
      } /*.skejule*/
    } /*.checkbox*/
  } /*.toggle*/
} /*.ui*/

/******************* CUSTOM TOGGLE ********************/

/******************* CUSTOM FONT SIZE ********************/

.bigtext {
  font-size: 18px !important;
  font-weight: bold !important;
}

.tinytext {
  font-size: 11px !important;
}

/******************* CUSTOM FONT SIZE ********************/

/******************* MESSAGE BOARD ********************/
.message-board {
  .message {
    min-height: 32px;
    padding: 5px 32px;
    line-height: 22px;
    font-size: 14px;
    letter-spacing: 0.3px;
    width: 100%;
    margin: -10px 0 !important;

    .header {
      margin-right: 5px;
      font-family: unset;
      font-size: 14px !important;
      letter-spacing: 0.3px;

      & + p {
        margin-top: -2px;
      }
    }

    span {
      display: unset !important;
    }
    & > .close {
      &.icon {
        top: 6px;
      }
    }
    &.info {
      color: #74c0d0;
      box-shaddow: 0 0 0 1px #74c0d0 inset, 0 0 0 0 transparent;
      background: #e3f2f6 url($path-images + "message-info.svg") no-repeat 7px
        7px;
      background-size: 20px;
      & .header {
        color: #74c0d0;
      }
    }
    &.warning {
      color: #e79b1c;
      box-shaddow: 0 0 0 1px #e79b1c inset, 0 0 0 0 transparent;
      background: #eadfd6 url($path-images + "message-warning.svg") no-repeat
        7px 7px;
      background-size: 20px;
      & .header {
        color: #e79b1c;
      }
    }
    &.negative {
      color: #d0021b;
      box-shaddow: 0 0 0 1px #d0021b inset, 0 0 0 0 transparent;
      background: #e9caca url($path-images + "message-negative.svg") no-repeat
        7px 7px;
      background-size: 20px;
      & .header {
        color: #d0021b;
      }
    }
    &.success {
      color: #66ad19;
      box-shaddow: 0 0 0 1px #66ad19 inset, 0 0 0 0 transparent;
      background: #e0eed7 url($path-images + "message-success.svg") no-repeat
        7px 7px;
      background-size: 20px;
      & .header {
        color: #66ad19;
      }
    }
  }
}
/******************* MESSAGE BOARD ********************/

/******************* INPUT FIELD THEMING ********************/

.ui {
  .info {
    color: #74c0d0;
    input {
      color: #74c0d0;
      // background: #e3f2f6;
      border: 1px solid #74c0d0;
      border-radius: 100px;
    }
  }
  .warning {
    color: #e79b1c;
    input {
      color: #e79b1c;
      // background: #eadfd6;
      border: 1px solid #e79b1c;
      border-radius: 100px;
    }
  }
  .negative {
    color: #d0021b;

    input {
      color: #d0021b;
      // background: #e9caca;
      border: 1px solid #d0021b;
      border-radius: 100px;
    }
  }
  .success {
    color: #66ad19;

    input {
      color: #66ad19;
      // background: #e0eed7;
      border: 1px solid #66ad19;
      border-radius: 100px;
    }
  }

  .labeled {
    &.info {
      input {
        border-right-color: #74c0d0 !important;
      }
    }
    &.warning {
      input {
        border-right-color: #e79b1c !important;
      }
    }
    &.negative {
      input {
        border-right-color: #d0021b !important;
      }
    }
    &.success {
      input {
        border-right-color: #66ad19 !important;
      }
      .icon {
        color: #66ad19 !important;
        opacity: 1 !important;
      }
    }
  }

  .pulse-info,
  .pulse-info-inverted {
    box-shadow: 0 0 0 0 rgba(116, 192, 208, 0);
    animation: pulso-info 2s infinite;
    border-radius: 4px;
    &.input {
      box-shadow: 0 0 0 0 rgba(116, 192, 208, 0);
      animation: none;
      input {
        border-radius: 4px;
        box-shadow: 0 0 0 0 rgba(116, 192, 208, 0);
        animation: pulso-info 2s infinite;
      }
    }
    &.checkbox {
      box-shadow: 0 0 0 0 rgba(116, 192, 208, 0);
      animation: none;
      label {
        &:before {
          border-radius: 4px;
          box-shadow: 0 0 0 0 rgba(116, 192, 208, 0);
          animation: pulso-info 2s infinite;
        }
      }
    }
  }

  .pulse-info:hover {
    animation: none;
  }

  @-webkit-keyframes pulso-info {
    0% {
      -webkit-box-shadow: 0 0 0 0 rgba(116, 192, 208, 1);
    }
    70% {
      -webkit-box-shadow: 0 0 0 10px rgba(116, 192, 208, 0);
    }
    100% {
      -webkit-box-shadow: 0 0 0 0 rgba(116, 192, 208, 0);
    }
  }
  @keyframes pulso-info {
    0% {
      -moz-box-shadow: 0 0 0 0 rgba(116, 192, 208, 1);
      box-shadow: 0 0 0 0 rgba(116, 192, 208, 1);
    }
    70% {
      -moz-box-shadow: 0 0 0 10px rgba(116, 192, 208, 0);
      box-shadow: 0 0 0 10px rgba(116, 192, 208, 0);
    }
    100% {
      -moz-box-shadow: 0 0 0 0 rgba(116, 192, 208, 0);
      box-shadow: 0 0 0 0 rgba(116, 192, 208, 0);
    }
  }

  .pulse-warning {
    box-shadow: 0 0 0 0 rgba(200, 144, 101, 0);
    animation: pulso-warning 2s infinite;
    border-radius: 4px;
    &.input {
      box-shadow: 0 0 0 0 rgba(200, 144, 101, 0);
      animation: none;
      input {
        border-radius: 4px;
        box-shadow: 0 0 0 0 rgba(200, 144, 101, 0);
        animation: pulso-warning 2s infinite;
      }
    }
    &.checkbox {
      box-shadow: 0 0 0 0 rgba(200, 144, 101, 0);
      animation: none;
      label {
        &:before {
          border-radius: 4px;
          box-shadow: 0 0 0 0 rgba(200, 144, 101, 0);
          animation: pulso-warning 2s infinite;
        }
      }
    }
  }

  .pulse-warning:hover {
    animation: none;
  }

  @-webkit-keyframes pulso-warning {
    0% {
      -webkit-box-shadow: 0 0 0 0 rgba(200, 144, 101, 1);
    }
    70% {
      -webkit-box-shadow: 0 0 0 10px rgba(200, 144, 101, 0);
    }
    100% {
      -webkit-box-shadow: 0 0 0 0 rgba(200, 144, 101, 0);
    }
  }
  @keyframes pulso-warning {
    0% {
      -moz-box-shadow: 0 0 0 0 rgba(200, 144, 101, 1);
      box-shadow: 0 0 0 0 rgba(200, 144, 101, 1);
    }
    70% {
      -moz-box-shadow: 0 0 0 10px rgba(200, 144, 101, 0);
      box-shadow: 0 0 0 10px rgba(200, 144, 101, 0);
    }
    100% {
      -moz-box-shadow: 0 0 0 0 rgba(200, 144, 101, 0);
      box-shadow: 0 0 0 0 rgba(200, 144, 101, 0);
    }
  }

  .pulse-negative {
    box-shadow: 0 0 0 0 rgba(197, 38, 38, 0);
    animation: pulso-negative 2s infinite;
    border-radius: 4px;
    &.input {
      box-shadow: 0 0 0 0 rgba(197, 38, 38, 0);
      animation: none;
      input {
        border-radius: 4px;
        box-shadow: 0 0 0 0 rgba(197, 38, 38, 0);
        animation: pulso-negative 2s infinite;
      }
    }
    &.checkbox {
      box-shadow: 0 0 0 0 rgba(197, 38, 38, 0);
      animation: none;
      label {
        &:before {
          border-radius: 4px;
          box-shadow: 0 0 0 0 rgba(197, 38, 38, 0);
          animation: pulso-negative 2s infinite;
        }
      }
    }
  }

  .pulse-negative:hover {
    animation: none;
  }

  @-webkit-keyframes pulso-negative {
    0% {
      -webkit-box-shadow: 0 0 0 0 rgba(197, 38, 38, 1);
    }
    70% {
      -webkit-box-shadow: 0 0 0 10px rgba(197, 38, 38, 0);
    }
    100% {
      -webkit-box-shadow: 0 0 0 0 rgba(197, 38, 38, 0);
    }
  }
  @keyframes pulso-negative {
    0% {
      -moz-box-shadow: 0 0 0 0 rgba(197, 38, 38, 1);
      box-shadow: 0 0 0 0 rgba(197, 38, 38, 1);
    }
    70% {
      -moz-box-shadow: 0 0 0 10px rgba(197, 38, 38, 0);
      box-shadow: 0 0 0 10px rgba(197, 38, 38, 0);
    }
    100% {
      -moz-box-shadow: 0 0 0 0 rgba(197, 38, 38, 0);
      box-shadow: 0 0 0 0 rgba(197, 38, 38, 0);
    }
  }

  .pulse-success {
    box-shadow: 0 0 0 0 rgba(32, 138, 25, 0);
    animation: pulso-success 2s infinite;
    border-radius: 4px;
    &.input {
      box-shadow: 0 0 0 0 rgba(32, 138, 25, 0);
      animation: none;
      input {
        border-radius: 4px;
        box-shadow: 0 0 0 0 rgba(32, 138, 25, 0);
        animation: pulso-success 2s infinite;
      }
    }
    &.checkbox {
      box-shadow: 0 0 0 0 rgba(32, 138, 25, 0);
      animation: none;
      label {
        &:before {
          border-radius: 4px;
          box-shadow: 0 0 0 0 rgba(32, 138, 25, 0);
          animation: pulso-success 2s infinite;
        }
      }
    }
  }

  .pulse-success:hover {
    animation: none;
  }

  @-webkit-keyframes pulso-success {
    0% {
      -webkit-box-shadow: 0 0 0 0 rgba(32, 138, 25, 1);
    }
    70% {
      -webkit-box-shadow: 0 0 0 10px rgba(32, 138, 25, 0);
    }
    100% {
      -webkit-box-shadow: 0 0 0 0 rgba(32, 138, 25, 0);
    }
  }
  @keyframes pulso-success {
    0% {
      -moz-box-shadow: 0 0 0 0 rgba(32, 138, 25, 1);
      box-shadow: 0 0 0 0 rgba(32, 138, 25, 1);
    }
    70% {
      -moz-box-shadow: 0 0 0 10px rgba(32, 138, 25, 0);
      box-shadow: 0 0 0 10px rgba(32, 138, 25, 0);
    }
    100% {
      -moz-box-shadow: 0 0 0 0 rgba(32, 138, 25, 0);
      box-shadow: 0 0 0 0 rgba(32, 138, 25, 0);
    }
  }
}

/******************* INPUT FIELD THEMING ********************/

/******************* CUSTOM COLORED LOADER ********************/

.ui {
  &.loader {
    &:before {
      margin: 0px !important;
    }
    &:after {
      border-color: #74c0d0 transparent transparent;
    }
    &:before,
    &:after {
      margin: 0px !important;
    }
    &.inline {
      top: -2px;
    }
  }
  &.tab.loading.segment {
    &:after {
      border-color: #74c0d0 transparent transparent;
    }
  }
}

/******************* CUSTOM COLORED LOADER ********************/

/******************* CUSTOM ICONS ********************/

[class^="icono-"],
[class*=" icono-"] {
  font-size: 27px !important;
  vertical-align: middle !important;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.icono,
.icon {
  letter-spacing: 0;
  &.tiny {
    font-size: 12px !important;
  }

  &.small {
    font-size: 18px !important;
  }

  &.medium {
    font-size: 24px !important;
  }

  &.big {
    font-size: 32px !important;
  }

  &.huge {
    font-size: 55px !important;
  }

  &.massive {
    font-size: 200px !important;
  }

  &.rounded-icon {
    border-radius: 500em;
    line-height: 1;
  }

  // Actions doing something
  &.action {
    /* all other browsers */
    @keyframes action-loading {
      from {
        -moz-transform: rotateZ(0deg);
        -ms-transform: rotateZ(0deg);
        transform: rotateZ(0deg);
      }
      to {
        -moz-transform: rotateZ(-360deg);
        -ms-transform: rotateZ(-360deg);
        transform: rotateZ(-360deg);
      }
    }
    &.icono-reload-border.action-loading,
    &.icono-reload-fill.action-loading {
      animation: action-loading 2s infinite ease-out;
    }
    &.active {
      cursor: pointer;
    }
    &.disabled {
      opacity: 0.4;
      cursor: default;
    }
  }
}

.icono {
  &.rounded-icon {
    /*padding: 0.3em 0.3em;
    width: 1.7em;
    height: 1.7em;*/
  }
}

.icon {
  &.rounded-icon {
    padding: 0.3em 0.3em;
    width: 1.7em;
    height: 1.7em;
  }
}

.tooltip {
  position: relative;

  &:hover {
    &:after {
      position: absolute;
      right: 30px;
      /* float: left; */
      content: attr(title);
      color: #ffffff;
      background: #74c0d0;
      border-radius: 3px;
      width: 100px;
      font-size: 10px;
      font-family: "Montserrat", sans-serif;
      font-weight: bold;
      text-align: center;
      z-index: 100;
      padding: 6px;
      line-height: 15px;
    }
  }
} /*.tooltip*/

/******************* CUSTOM ICONS ********************/

/******************* CUSTOM DIVIDER ********************/

.ui {
  &.horizontal {
    &.divider {
      &.skejule-divider {
        color: #9e9d9d;
        text-transform: capitalize;
        letter-spacing: -0.19px;
        display: flex;
        line-height: 25px;

        &:before {
          width: 0% !important;
          background-size: 100% auto;
        }
        &:after {
          width: 100% !important;
          background-size: 100% auto;
        }

        &.divider-with-icon {
          i {
            position: sticky;
            left: 660px;
          }

          &:after {
            margin-right: 40px;
          }
        }
      }

      &.emergency {
        color: #d0021b !important;
        &:before,
        &:after {
          background-image: url($path-images + "emergencydivider.png") !important;
          background-size: 100% auto;
        }
      }
    }
  }
}

/******************* CUSTOM DIVIDER ********************/

/******************* CUSTOM CONTAINER ********************/

.shadowcontainer {
  box-shadow: 0px 2px 8px 0 rgba(217, 217, 217, 0.5);
  border-radius: 9px;
  padding: 15px;
}

.innercontainer {
  border-radius: 5px;
  border: 1px solid #e0e0e0;
  padding: 25px;
  min-height: 165px;

  .borderright {
    border-right: 0px;
    border-bottom: 1px solid #e0e0e0;
    margin-left: 14px;
    margin-right: 14px;
  }

  &.dotted {
    border-style: dotted !important;
  }

  &.dashed {
    border-style: dashed !important;
  }

  @media (min-width: 768px) {
    .borderright {
      border-right: 1px solid #e0e0e0;
      border-bottom: 0px;
      margin-left: 0px;
      margin-right: 0px;
    }
  }
}

/******************* CUSTOM CONTAINER ********************/

/******************* CUSTOM ATOM METER ********************/

.atom-meter {
  text-align: center;
  .atom {
    font-size: 24px;
    color: #9e9d9d;
    letter-spacing: 0.3px;
    border: 1px solid #e0e0e0;
    width: 70px;
    height: 70px;
    margin: 0 auto;
    border-radius: 35px;
    line-height: 70px;
    margin-bottom: 15px;
    box-shadow: 0px 2px 8px 0 rgba(217, 217, 217, 0.5);
    position: relative;

    .electron {
      background: #9e9d9d;
      width: 19px;
      height: 19px;
      border-radius: 19px;
      box-shadow: 0 0 0 5px rgba(158, 157, 157, 0.63);
      box-sizing: content-box;
      position: absolute;
      top: 10px;
      right: -10px;
    }
  }
  .atom-label {
    font-weight: lighter;
    font-size: 14px;
    color: #9e9d9d;
    letter-spacing: -0.19px;
    text-transform: uppercase;
  }
  &.info {
    .atom {
      border-color: #74c0d0 !important;
      .electron {
        background: #74c0d0;
        box-shadow: 0 0 0 5px rgba(116, 192, 208, 0.63);
      }
    }
  }
  &.warning {
    .atom {
      border-color: #e79b1c !important;
      .electron {
        background: #e79b1c;
        box-shadow: 0 0 0 5px rgba(200, 144, 101, 0.63);
      }
    }
  }
  &.negative {
    .atom {
      border-color: #d0021b !important;
      .electron {
        background: #d0021b;
        box-shadow: 0 0 0 5px rgba(197, 38, 38, 0.63);
      }
    }
  }
  &.success {
    .atom {
      border-color: #66ad19 !important;
      .electron {
        background: #66ad19;
        box-shadow: 0 0 0 5px rgba(32, 138, 25, 0.63);
      }
    }
  }
}

/******************* CUSTOM ATOM METER ********************/

/******************* CUSTOM AVATAR ********************/

img.avatar,
.ui.avatar.image {
  max-width: unset;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  background-color: #fff;

  &.with-border {
    border: 1px solid #b2b2b2;
    &.user-logged-in {
      border: 2px solid #74c0d0;
    }
  }

  &.tiny {
    width: 27px;
    height: 27px;
  }

  &.small {
    width: 54px;
    height: 54px;
  }

  &.medium {
    width: 70px;
    height: 70px;
  }

  &.big {
    width: 120px;
    height: 120px;
  }
  &.huge {
    width: 150px;
    height: 150px;
    &.with-border {
      border: 6px solid #74c0d0;
    }
  }
  &.empty {
    border: 0px;
  }
}

.profile-avatar {
  .upload-file {
    transition: 0.5s ease;
    opacity: 0;
    position: absolute;
    top: 22px;
    left: 22px;
    text-align: center;
  }
  .dropzone {
    &.loading,
    &.error {
      img {
        opacity: 0.3;
      }
    }
    &.error {
      .upload-file {
        opacity: 1;
      }
    }
    &:hover {
      img {
        opacity: 0.3;
      }
      .upload-file {
        opacity: 1;
      }
    }
  }
}

/******************* CUSTOM AVATAR ********************/

/******************* CUSTOM TABS ********************/

.ui {
  &.secondary {
    &.pointing {
      &.menu {
        .active {
          &.item {
            border-color: #74c0d0;
            color: #74c0d0;
          }
        }
        .item {
          color: #999999;
          &:hover,
          &:focus {
            border-color: #74c0d0 !important;
            color: #74c0d0 !important;
          }
        }
      }
    }
  }
}

/******************* CUSTOM TABS ********************/

/******************* CUSTOM TABLE ********************/

.results {
  overflow-x: auto;
  overflow-y: hidden;
  box-shadow: 0px 2px 8px 0 rgba(217, 217, 217, 0.5);

  .ui {
    &.table {
      &.skejule {
        min-width: 900px;
        border: 0px;
        color: #999999;
        letter-spacing: 0.3px;
        font-size: 14px;

        .name,
        .last-name,
        .number,
        th {
          color: #3765b3;
        }
        .email,
        .program,
        .cohort,
        .term,
        .files {
        }
        .email {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
        .files img {
          width: 26px;
          height: 26px;
        }
        thead {
          tr {
            /*								box-shadow: inset 0 -1px 0 0 rgba(0,0,0,0.05);*/
            th {
              background: #ffffff;
              height: 37px;
              border-left: 0px;
              border-bottom: 0px;
              padding: 7px 0.78571429em;
              /*									box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.05);*/
              &.not-sort {
                cursor: auto;
              }
              &.asc-desc {
                &:after {
                  display: inline-block;
                  content: "❮❯";
                  transform: rotate(-90deg);
                  transform-origin: center;
                  color: #9e9d9d;
                  position: relative;
                  left: 7px;
                  top: 5px;
                }
              }
              &.descending {
                &:after {
                  content: "❯";
                  transform: rotate(90deg);
                  transform-origin: center;
                  color: #9e9d9d;
                  position: relative;
                  left: 20px;
                  top: 5px;
                }
              }
              &.ascending {
                &:after {
                  content: "❯";
                  transform: rotate(-90deg);
                  transform-origin: center;
                  color: #9e9d9d;
                  position: relative;
                  left: 7px;
                  top: 5px;
                }
              }
              &:first-child {
                .checkbox {
                  margin-top: 3px;
                }
              }
            }
          }
        }
        .skejule-scroll {
          height: auto;
        }

        @media (min-width: 768px) {
          .skejule-scroll {
            height: calc(100vh - 350px);
          }
        }

        tbody {
          box-shadow: inset 0 1px 0 0 rgba(0, 0, 0, 0.05);

          tr {
            /*min-height: 60px;*/
            box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.05);
            display: flex;
            &:nth-child(n) {
              background-color: #f9f9f9;
            }
            &:nth-child(2n) {
              background-color: #ffffff;
            }
            &.active {
              background: linear-gradient(
                -135deg,
                rgba(35, 188, 186, 0.05) 0%,
                rgba(69, 174, 233, 0.05) 100%
              ) !important;
              td {
                &:first-child {
                  background: url($path-images + "active-row.svg") no-repeat
                    left center !important;
                }
              }
            }

            td {
              /*min-height: 60px;*/
              /*padding: 18px 0.78571429em 14px;*/
              padding: 7px 14px 7px 10px;
              align-self: center;

              border-top: 0px;
              &.name {
                position: relative;
                .viewprofile {
                  position: absolute;
                  bottom: 0px;
                  text-transform: lowercase;
                  opacity: 0.75;
                  font-weight: 100;
                  font-size: 11px;
                  color: #74bddb;
                  letter-spacing: 0.3px;
                  display: none;
                }
              }

              .checkbox {
                min-height: 13px;
              }
            }

            &:hover,
            &.active {
              td {
                &.name {
                  .viewprofile {
                    display: block;
                  }
                }
              }
            }
          }
        }
        &.fixed-header {
          width: 100%;
          background-color: #ffffff;
          tbody {
            width: 100%;
          }
          thead,
          thead tr,
          tbody,
          td,
          th {
            display: block;
          }
          tbody {
            td {
              float: left;
            }
          }
          thead {
            padding-right: 5px;
            tr {
              th {
                float: left;
              }
            }
          }
        }
        &:not(.unstackable) tbody,
        &:not(.unstackable) tr,
        &:not(.unstackable) tr > td,
        &:not(.unstackable) tr > th {
          width: blabla !important;
          display: blabla !important;
        }

        &.infinite-width {
          &.fixed-header {
            thead,
            thead tr,
            tbody,
            td,
            th {
              display: table-header-group !important;
            }
          }

          td,
          th {
            &.one.wide {
              width: 100px !important;
            }
            &.two.wide {
              width: 200px !important;
            }
            &.three.wide {
              width: 300px !important;
            }
            &.four.wide {
              width: 400px !important;
            }
            &.five.wide {
              width: 500px !important;
            }
            &.six.wide {
              width: 600px !important;
            }
            &.seven.wide {
              width: 700px !important;
            }
            &.eight.wide {
              width: 800px !important;
            }
            &.nine.wide {
              width: 900px !important;
            }
            &.ten.wide {
              width: 1000px !important;
            }
            &.eleven.wide {
              width: 1100px !important;
            }
            &.twelve.wide {
              width: 1200px !important;
            }
            &.thirteen.wide {
              width: 1300px !important;
            }
            &.fourteen.wide {
              width: 1400px !important;
            }
            &.fifteen.wide {
              width: 1500px !important;
            }
            &.sixteen.wide {
              width: 1600px !important;
            }
          }

          .skejule-scroll {
            overflow-x: hidden;
          }
        } /*.infinite-width*/
      } /*&.skejule*/
    } /*&.table*/
  } /*.ui*/

  &.stripped {
    box-shadow: 0px 0px 0px transparent;
    margin-top: 0px;
    padding-top: 20px;
    padding-bottom: 20px;
    border-top: 2px solid #9b9b9b;
    border-bottom: 2px solid #9b9b9b;

    .ui {
      &.table {
        &.skejule {
          tbody {
            box-shadow: 0px 0px 0px transparent;

            tr {
              box-shadow: 0px 0px 0px transparent;
            }

            tr:nth-child(n) {
              background-color: transparent;
            }
          } /*tbody*/

          .skejule-scroll {
            border-top: 0px;
          }
        } /*&.skejule*/
      } /*&.table*/
    } /*.ui*/
  } /*.stripped*/
} /*.results*/

/******************* CUSTOM TABLE ********************/

/******************* HETO TABLE ********************/

.heto-results-table {
  // border-radius: 10px;
  box-shadow: 0px 2px 8px 0 rgba(217, 217, 217, 0.5);
  height: auto;
  &.heto-scroll {
    @include heto-scroll("companies");
    &.fixed-header {
      &::-webkit-scrollbar-track {
        margin-top: 43px !important;
      }
    }
  }
  .ui {
    &.table {
      &.heto {
        border: 0px;
        color: #999999;
        font-size: 14px;
        letter-spacing: 0.3px;
        min-width: 900px;
        thead {
          tr {
            th {
              background: #ffffff;
              border-bottom: 0px;
              border-left: 0px;
              color: #3765b3;
              height: 37px;
              padding: 7px 0.78571429em;
              &.not-sort {
                cursor: auto;
              }
              &.asc-desc {
                &:after {
                  color: #9e9d9d;
                  content: "❮❯";
                  display: inline-block;
                  left: 7px;
                  position: relative;
                  top: 5px;
                  transform: rotate(-90deg);
                  transform-origin: center;
                }
              }
              &.descending {
                &:hover {
                  background: #ffffff;
                }
                &:after {
                  color: #9e9d9d;
                  content: "❯";
                  left: 20px;
                  position: relative;
                  top: 5px;
                  transform: rotate(90deg);
                  transform-origin: center;
                }
              }
              &.ascending {
                &:hover {
                  background: #ffffff;
                }
                &:after {
                  color: #9e9d9d;
                  content: "❯";
                  left: 7px;
                  position: relative;
                  top: 5px;
                  transform: rotate(-90deg);
                  transform-origin: center;
                }
              }
              &:first-child {
                .checkbox {
                  margin-top: 5px;
                }
              }
            }
          }
        }
        tbody {
          box-shadow: inset 0 1px 0 0 rgba(0, 0, 0, 0.05);
          tr {
            box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.05);
            &:nth-child(n) {
              background-color: #f9f9f9;
            }
            &:nth-child(2n) {
              background-color: #ffffff;
            }
            &.active {
              background: linear-gradient(
                -135deg,
                rgba(35, 188, 186, 0.05) 0%,
                rgba(69, 174, 233, 0.05) 100%
              ) !important;
              td {
                &:first-child {
                  background: url($path-images + "active-row.svg") no-repeat
                    left center !important;
                }
              }
            }
            td {
              padding: 7px 10px;
              border-top: 0px;
              .checkbox {
                min-height: 13px;
              }
              span,
              div {
                &.link {
                  color: #3765b3;
                  &:hover {
                    cursor: pointer;
                    text-decoration: underline;
                  }
                }
              }
            }
          }
        }
        &.fixed-header {
          thead {
            tr {
              th {
                position: sticky;
                top: 0;
                z-index: 10;
              }
            }
          }
        }
      } /*&.heto*/
    } /*&.table*/
  } /*.ui*/

  &.stripped {
    border-bottom: 2px solid #9b9b9b;
    border-top: 2px solid #9b9b9b;
    box-shadow: 0px 0px 0px transparent;
    margin-top: 0px;
    padding-bottom: 20px;
    padding-top: 20px;
    .ui {
      &.table {
        &.skejule {
          tbody {
            box-shadow: 0px 0px 0px transparent;
            tr {
              box-shadow: 0px 0px 0px transparent;
            }
            tr:nth-child(n) {
              background-color: transparent;
            }
          } /*tbody*/
          .skejule-scroll {
            border-top: 0px;
          }
        } /*&.skejule*/
      } /*&.table*/
    } /*.ui*/
  } /*.stripped*/

  &.very-basic {
    box-shadow: none;
    &.heto-scroll {
      &.fixed-header {
        &::-webkit-scrollbar-track {
          margin-top: 38px !important;
        }
      }
    }
    .ui {
      &.table {
        &.heto {
          thead {
            tr {
              th {
                border-bottom: 1px solid rgba(34, 36, 38, 0.1);
              }
            }
          }
        } /*&.heto*/
      } /*&.table*/
    } /*.ui*/
  }
} /*.results*/

/******************* CUSTOM TABLE ********************/

/******************* CUSTOM INFO ELEMENTS ********************/

.info-elements {
  .info-element {
    margin-bottom: 5px;
    hyphens: auto;

    .label {
      font-weight: bold;
      color: #3765b3;
      &:after {
        content: ": ";
      }
    }

    .item {
      font-weight: bold;
      color: #3765b3;
      &:before {
        content: "- ";
      }
    }

    .selected-file {
      color: #3765b3;
      line-height: 20px;
      font-weight: bold;
      font-size: 16px;
    }
    .value {
    }

    &.table {
      margin-bottom: 30px;
      .value {
        display: block;
        padding: 5px 20px;

        &:nth-child(n) {
          background: #ffffff;
        }

        &:nth-child(2n) {
          background: #ecf5f6;
        }
      }
    }
  } /*.info-element*/
} /*.info-elements*/

/******************* CUSTOM INFO ELEMENTS ********************/

/******************* CUSTOM ELLIPSIS ********************/

.ellipsis {
  overflow: hidden;
  position: relative;
  line-height: 1.2em;
  max-height: 1.2em;
  text-align: justify;
  margin-right: -1em;
  padding-right: 1em;

  &.two {
    max-height: 2.4em !important;
  }

  &.three {
    max-height: 3.6em !important;
  }

  &:before {
    content: "...";
    position: absolute;
    right: 0;
    bottom: 0;
  }

  &:after {
    content: "";
    position: absolute;
    right: 0;
    width: 1em;
    height: 1em;
    margin-top: 0.2em;
    background: white;
  }
}

/******************* CUSTOM ELLIPSIS ********************/

/******************* CUSTOM INLINE EDIT ********************/
.editable {
  padding-right: 30px;
  cursor: text;

  &:hover {
    position: relative;

    &:after {
      font-family: "skejule" !important;
      font-size: 28px;
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      content: "\e98c";
      position: absolute;
      top: -5px;
      right: 0px;
    } /*:after*/
  } /*:hover*/
} /*.editable*/

/******************* CUSTOM INLINE EDIT ********************/

/******************* WARNING POPUP MESSAGE ********************/
body.warning-popup-heto {
  .ui.modals.dimmer {
    background-color: rgba(0, 0, 0, 0.5);
    div.modal.confirm-msg {
      .skejule-gray {
        color: #999 !important;
      }
      div.ui.list {
        border-top: 0;
        &.skejule-scroll {
          height: 45vh;
        }
        div.item {
          padding: 10px;
          &:nth-child(2n) {
            background-color: #f9f9f9;
          }
          &:last-child {
            span.process-action {
              i.status-label {
                &.tooltip {
                  &:after {
                    top: -35px;
                  }
                  &.completed {
                    &:after {
                      top: 0px;
                    }
                  }
                }
              }
            }
          }
          span.process-action {
            i.status-label {
              &.tooltip {
                &:after {
                  top: -5px;
                  width: 300px;
                  font-size: 14px;
                  text-align: justify;
                }
                &.completed {
                  &:after {
                    top: 0px;
                    width: 100px;
                    text-align: center;
                  }
                }
              }
            }
          }
          &:first-child {
            span.process-action {
              i.status-label {
                &.tooltip {
                  &:after {
                    top: -5px;
                  }
                  &.completed {
                    &:after {
                      top: 0px;
                    }
                  }
                }
              }
            }
          }
        }
      }
      &.completed {
        .actions {
          button {
            &.ui.button.cancel-btn {
              display: none;
            }
          }
        }
      }
    }
  }
}
/******************* WARNING POPUP MESSAGE ********************/

/******************* CUSTOM MODALS ********************/
.ui {
  &.page {
    &.modals {
      &.dimmer {
        background-color: rgba(0, 0, 0, 0.25);
      }

      .modal {
        -webkit-box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.5);
        box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.5);
        &.confirm-msg {
          padding: 0;
          padding-bottom: 10px;
          @media (min-width: 767px) {
            padding-bottom: 0;
          }
        }
      }
    }
  }
}

body.blurring {
  .ui {
    &.page {
      &.modals {
        &.dimmer {
          background-color: rgba(0, 0, 0, 0.6);
        }
      }
    }
  }
}

/******************* CUSTOM MODALS ********************/
/******************* CUSTOM POPUP SOCIAL MEDIA ********/
#social-media-popup {
  &.ui.top {
    top: auto !important;
  }
  &.ui.bottom {
    bottom: auto !important;
  }
  .info-elements {
    width: 320px;
    .info-element {
      .label {
        font-weight: normal;
        a.link {
          font-weight: bold;
          color: #3765b3;
          &:hover {
            cursor: pointer;
            text-decoration: underline;
          }
        }
      }
      input {
        &.edit-inline {
          cursor: default;
          font-size: 14px;
          outline-width: 0;
          padding: 0 !important;
          margin: 0;
          border-right-width: 0;
          border-left-width: 0;
          border-top-width: 0;
          border-bottom: 1px;
          border-style: solid;
          line-height: 0px;
          width: 60%;
        }
      }
    }
    .value {
      color: #999;
      .editable {
        &:hover:after {
          top: auto;
          bottom: -5px;
        }
      }
    }
  }
}
/******************* CUSTOM POPUP SOCIAL MEDIA ********/

/******************* CUSTOM FIELDS ********************/
.info-elements.custom-fields {
  .info-element.input-url {
    .ui.input > input {
      border: 0;
      border-bottom: 1px solid rgba(34, 36, 38, 0.15);
      color: #999999;
      font-size: initial;
    }
  }
  .info-element.checkbox {
    .ui.checkbox {
      top: 4px;
    }
  }
}
/******************* CUSTOM FIELDS ********************/
