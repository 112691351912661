#root #heto-app-website {
  .heto-emulate-user-widget {
    margin: 0;
    @media (min-width: 768px) {
      padding-left: 82px;
    }
    .emulate-panel {
      background: #6ac7da;
      color: #fff;
      padding: 5px;
      z-index: 101;
      .ui.icon.button.basic.stop-emulation {
        box-shadow: unset;
        margin: 0;
        padding: 0 !important;
        i.close.icon {
          color: #fff;
        }
        &.tooltip {
          &:after {
            bottom: -1px;
          }
        }
        &:hover {
          i.close.icon {
            color: #77c0cf;
          }
        }
        &.loading {
          i.close.icon {
            visibility: hidden;
          }
          &::after {
            border-top-color: #fff;
          }
        }
      }
    }
  }
}

body.emulate-active-heto {
  #main {
    div.body.visible {
      margin-top: 35px;
    }
    &.reports.reports-query-params.create.edit,
    &.reports.reports-query-info.create.edit,
    &.evaluations-schedulers.create {
      div.body.visible {
        margin-top: 0;
      }
    }
  }
}
